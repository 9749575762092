"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.make18n = void 0;
var utils_1 = require("../../utils");
var utils_2 = require("./utils");
var locale;
function make18n(props) {
    var initialLocale = props.initialLocale, persistor = props.persistor, languageDictionary = props.languageDictionary;
    locale = initialLocale;
    var t = function (key, args, customLocale) {
        var _a;
        var dict = languageDictionary === null || languageDictionary === void 0 ? void 0 : languageDictionary[customLocale || locale];
        if (!dict)
            return key;
        var value = (0, utils_2.getNestedValues)(dict, key);
        if (!value) {
            (_a = console.warn) === null || _a === void 0 ? void 0 : _a.call(console, "Missing translation for key: ".concat(key, " in locale: ").concat(customLocale || locale));
            return key;
        }
        if (utils_1.TypeGuards.isFunction(value))
            return value(args);
        if (utils_1.TypeGuards.isArray(args))
            return utils_2.formatStrWithArgs.apply(void 0, __spreadArray([value], __read(args), false));
        return (0, utils_2.replaceVariables)(value, args);
    };
    var setLocale = function (newLocale) {
        locale = newLocale;
    };
    return {
        t: t,
        setLocale: setLocale,
        persistor: persistor,
        languageDictionary: languageDictionary,
        locale: locale,
    };
}
exports.make18n = make18n;
