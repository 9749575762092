"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createViewStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createViewStyle(function () { return ({ wrapper: styles }); }); });
exports.ViewStyles = __assign(__assign({}, presets), { default: createViewStyle(function (t) { return ({
    // wrapper: {
    //   display: 'flex',
    // },
    }); }), separator: createViewStyle(function (t) { return ({
        wrapper: {
            width: '100%',
            height: t.values.pixel,
            backgroundColor: t.colors.borders,
            marginLeft: t.spacing.value(3),
        },
    }); }) });
