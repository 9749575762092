"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyVariants = exports.standardizeVariants = exports.mapVariants = void 0;
var object_1 = require("../../utils/object");
function mapVariants(theme, variantsObject) {
    var e_1, _a;
    var thisComponentVariants = {};
    var _loop_1 = function (variantName, variantBuilder) {
        // @ts-ignore
        thisComponentVariants[variantName] = function (t) { return variantBuilder(t, variantName); };
    };
    try {
        for (var _b = __values(Object.entries(variantsObject)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), variantName = _d[0], variantBuilder = _d[1];
            _loop_1(variantName, variantBuilder);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return thisComponentVariants;
}
exports.mapVariants = mapVariants;
function standardizeVariants(variants) {
    var variantList = [];
    if (!variants)
        return [];
    if (typeof variants === 'string') {
        variantList = variants.split(' ');
    }
    else {
        variantList = __spreadArray([], __read((variants.filter(function (v) { return !!v; }) || [])), false);
    }
    return variantList;
}
exports.standardizeVariants = standardizeVariants;
function applyVariants(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, e_2, _r;
    var computedStyles = _a.computedStyles, _s = _a.rootElement, rootElement = _s === void 0 ? 'wrapper' : _s, styles = _a.styles, theme = _a.theme, variantName = _a.variantName, _t = _a.wrapStyle, wrapStyle = _t === void 0 ? function (style) { return style; } : _t;
    if (typeof variantName !== 'string')
        return {};
    if (!styles[variantName]) {
        if (variantName.startsWith('padding') || variantName.startsWith('margin') || variantName.startsWith('gap')) {
            var _u = __read(variantName.split(':'), 2), spacingFunction = _u[0], multiplier = _u[1];
            var arg = Number(multiplier);
            if (Number.isNaN(arg)) {
                arg = multiplier;
            }
            return (0, object_1.deepMerge)(computedStyles, (_b = {},
                _b[rootElement] = wrapStyle(__assign({}, theme.spacing[spacingFunction](arg))),
                _b));
        }
        else if (variantName.startsWith('d:') && styles.dynamicHandler) {
            return (0, object_1.deepMerge)(computedStyles, styles.dynamicHandler(theme, variantName.replace('d:', '')));
        }
        else if (variantName.startsWith('w:') || variantName.startsWith('h:')) {
            var _v = __read(variantName.split(':'), 2), dimension = _v[0], size = _v[1];
            var value = 0;
            if (size.endsWith('su')) {
                value = theme.spacing.value(Number(size.replace('su', '').trim()));
            }
            else if (size.endsWith('px')) {
                value = Number(size.replace('px', '').trim());
            }
            else {
                value = "".concat(size, "%");
            }
            var dim = dimension === 'w' ? 'width' : 'height';
            return (0, object_1.deepMerge)(computedStyles, (_c = {},
                _c[rootElement] = wrapStyle((_d = {},
                    _d[dim] = value,
                    _d)),
                _c));
        }
        else if (variantName.startsWith('backgroundColor') || variantName.startsWith('bg') || variantName.startsWith('color')) {
            var _w = __read(variantName.split(':'), 2), property = _w[0], themeColor = _w[1];
            if (property === 'bg')
                property = 'backgroundColor';
            var value = theme.colors[themeColor];
            var browserOnly = theme.IsBrowser && { fill: value, stroke: value };
            return (0, object_1.deepMerge)(computedStyles, (_e = {},
                _e[rootElement] = wrapStyle(__assign((_f = {}, _f[property] = value, _f), browserOnly)),
                _e));
        }
        else if (variantName.startsWith('border')) {
            var _x = __read(variantName.split(':'), 2), properties = _x[0], value = _x[1];
            var _y = __read(properties.split('-'), 2), property = _y[0], type = _y[1];
            var borderStyle = {};
            switch (type) {
                case 'width':
                    borderStyle = (_g = {}, _g["".concat(property, "Width")] = theme.values.borderWidth[value], _g);
                    break;
                case 'style':
                    borderStyle = (_h = {}, _h["".concat(property, "Style")] = value, _h);
                    break;
                case 'radius':
                    borderStyle = (_j = {}, _j["".concat(property, "Radius")] = theme.borderRadius[value], _j);
                    break;
                case 'color':
                    borderStyle = (_k = {}, _k["".concat(property, "Color")] = theme.colors[value], _k);
                    break;
                default:
                    break;
            }
            return (0, object_1.deepMerge)(computedStyles, (_l = {}, _l[rootElement] = wrapStyle(borderStyle), _l));
        }
        else if (variantName.startsWith('scale') || variantName.startsWith('rotate') || variantName.startsWith('translate')) {
            var _z = __read(variantName.split(':'), 2), property = _z[0], value = _z[1];
            return (0, object_1.deepMerge)(computedStyles, (_m = {},
                _m[rootElement] = wrapStyle({
                    transform: theme.IsBrowser ? "".concat(property, "(").concat(value, ")") : [(_o = {},
                            _o[property] = property !== 'rotate' ? Number(value) : value,
                            _o)],
                }),
                _m));
        }
        else if (theme.IsBrowser) {
            if (variantName.startsWith('cursor')) {
                var _0 = __read(variantName.split(':'), 2), property = _0[0], value = _0[1];
                return (0, object_1.deepMerge)(computedStyles, (_p = {},
                    _p[rootElement] = wrapStyle((_q = {},
                        _q[property] = value,
                        _q)),
                    _p));
            }
        }
        return computedStyles;
    }
    else {
        var wrapped = {};
        var overWriteStyles = styles[variantName](theme);
        try {
            for (var _1 = __values(Object.entries(overWriteStyles)), _2 = _1.next(); !_2.done; _2 = _1.next()) {
                var _3 = __read(_2.value, 2), key = _3[0], val = _3[1];
                wrapped[key] = wrapStyle(val);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_2 && !_2.done && (_r = _1.return)) _r.call(_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return (0, object_1.deepMerge)(computedStyles, wrapped);
    }
}
exports.applyVariants = applyVariants;
