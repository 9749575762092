"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaginationData = exports.getPaginationKeys = exports.getQueryKeys = void 0;
var utils_1 = require("../../utils");
var getQueryKeys = function (base) {
    return {
        list: ["".concat(base, ".list")],
        create: ["".concat(base, ".create")],
        update: ["".concat(base, ".update")],
        remove: ["".concat(base, ".remove")],
        retrieve: ["".concat(base, ".retrieve")],
    };
};
exports.getQueryKeys = getQueryKeys;
var getPaginationKeys = function (base, queryClient) {
    return {
        list: queryClient.queryKey(["".concat(base, ".list")]),
        create: queryClient.queryKey(["".concat(base, ".create")]),
        update: queryClient.queryKey(["".concat(base, ".update")]),
        remove: queryClient.queryKey(["".concat(base, ".remove")]),
        retrieve: queryClient.dynamicQueryKey(function (id) { return ["".concat(base, ".retrieve.").concat(id)]; }),
    };
};
exports.getPaginationKeys = getPaginationKeys;
function getPaginationData(params) {
    var e_1, _a;
    var pagesById = {};
    var flatItems = [];
    var itemMap = {};
    var listVal = params.queryKeyOrList;
    var list = params.queryKeyOrList;
    if (utils_1.TypeGuards.isArray(list)) {
        if (!params.queryClient) {
            throw new Error('No QueryClient passed to getPaginationData. It\'s mandatory when requesting queries by key');
        }
        listVal = params.queryClient.getQueryData(list).data;
    }
    var pageIdx = 0;
    var derivedData = null;
    try {
        for (var _b = __values(listVal.pages), _c = _b.next(); !_c.done; _c = _b.next()) {
            var page = _c.value;
            page.results.forEach(function (i, itemIdx) {
                var _a;
                var flatIdx = flatItems.length;
                var itemId = params.keyExtractor(i);
                var include = true;
                if (params === null || params === void 0 ? void 0 : params.filter) {
                    include = params === null || params === void 0 ? void 0 : params.filter(i, flatIdx, flatItems, itemMap, pagesById);
                }
                else {
                    include = !itemMap[itemId];
                }
                if (params === null || params === void 0 ? void 0 : params.derive) {
                    derivedData = (_a = params.derive) === null || _a === void 0 ? void 0 : _a.call(params, {
                        item: i,
                        context: {
                            passedFilter: include,
                        },
                        index: flatIdx,
                        arr: flatItems,
                        currentData: derivedData,
                    });
                }
                if (include) {
                    flatItems.push(i);
                }
                pagesById[itemId] = [pageIdx, itemIdx];
                itemMap[itemId] = i;
            });
            pageIdx += 1;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return {
        flatItems: flatItems,
        pagesById: pagesById,
        derivedData: derivedData,
        itemMap: itemMap,
    };
}
exports.getPaginationData = getPaginationData;
