"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextStyles = exports.assignTextStyle = void 0;
var utils_1 = require("../../../utils");
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createTextStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createTextStyle(function () { return ({ text: styles }); }); });
function assignTextStyle(name, add) {
    if (add === void 0) { add = {}; }
    return createTextStyle(function (theme) {
        var _a, _b, _c, _d, _e, _f;
        var style = theme.typography.base.styles[name];
        var fontFamily = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.typography) === null || _a === void 0 ? void 0 : _a.base) === null || _b === void 0 ? void 0 : _b.fontFamily;
        var fontWeight = style.weight.toString();
        var fontSize = style.size;
        var resolveFontFamily = theme.typography.base.resolveFontFamily;
        if (!utils_1.TypeGuards.isNil(resolveFontFamily)) {
            var fontName_1 = "".concat(fontFamily, "_").concat(fontWeight.toString(), "_").concat(fontSize.toString());
            if (utils_1.TypeGuards.isFunction(resolveFontFamily)) {
                fontFamily = resolveFontFamily(fontName_1, {
                    weight: Number(fontWeight),
                    family: fontFamily,
                    letterSpacing: (_c = style.letterSpacing) !== null && _c !== void 0 ? _c : null,
                    size: fontSize,
                });
            }
            else {
                var resolved = (_d = Object.entries(resolveFontFamily).find(function (_a) {
                    var _b = __read(_a, 1), key = _b[0];
                    return fontName_1.startsWith(key);
                })) === null || _d === void 0 ? void 0 : _d[1];
                if (resolved) {
                    fontFamily = resolved;
                }
            }
        }
        return {
            text: __assign({ fontWeight: fontWeight, fontFamily: fontFamily, fontSize: fontSize, lineHeight: (_e = style.lineHeight) !== null && _e !== void 0 ? _e : null, letterSpacing: (_f = style.letterSpacing) !== null && _f !== void 0 ? _f : null }, add),
        };
    });
}
exports.assignTextStyle = assignTextStyle;
exports.TextStyles = __assign(__assign({}, presets), { default: createTextStyle(function (theme) { return ({
        text: __assign({ fontFamily: theme.typography.base.fontFamily }, assignTextStyle('p1')(theme).text),
    }); }), hx: assignTextStyle('hx'), h0: assignTextStyle('h0'), h1: assignTextStyle('h1'), h2: assignTextStyle('h2'), h3: assignTextStyle('h3'), h4: assignTextStyle('h4'), h5: assignTextStyle('h5'), p1: assignTextStyle('p1'), p2: assignTextStyle('p2'), p3: assignTextStyle('p3'), p4: assignTextStyle('p4'), p5: assignTextStyle('p5'), link: assignTextStyle('p2'), OSAlertBody: createTextStyle(function (theme) { return ({
        text: __assign(__assign({}, assignTextStyle('p1')(theme).text), theme.presets.textCenter),
    }); }) });
