"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useI18N = exports.I18NProvider = exports.I18NContext = exports.I18NRef = void 0;
var react_1 = __importStar(require("react"));
var utils_1 = require("../../utils");
exports.I18NRef = react_1.default.createRef();
exports.I18NContext = react_1.default.createContext(null);
// @ts-expect-error
if (!exports.I18NRef.current)
    exports.I18NRef.current = {};
var I18NProvider = function (props) {
    var subscribers = (0, react_1.useRef)([]);
    var _a = __read(react_1.default.useState(false), 2), isSettingLocale = _a[0], setIsSettingsLocale = _a[1];
    var subscribe = react_1.default.useCallback(function (callback) {
        subscribers.current.push(callback);
        return function () {
            subscribers.current = subscribers.current.filter(function (cb) { return cb !== callback; });
        };
    }, []);
    exports.I18NRef.current.subscribe = subscribe;
    var children = props.children, i18n = props.i18n;
    var initialLocale = i18n.locale, persistor = i18n.persistor;
    var callSubscribers = react_1.default.useCallback(function (locale) {
        subscribers.current.forEach(function (cb) { return cb(locale); });
    }, []);
    var _b = __read(react_1.default.useState(function () {
        var _locale = initialLocale;
        var isPromise = false;
        if (persistor) {
            var persistedLocale = persistor.getLocale();
            // @ts-expect-error - TS doesn't know that a Promise has a then method
            isPromise = persistedLocale instanceof Promise || !!persistedLocale.then;
            if (!isPromise) {
                // @ts-expect-error - TS doesn't know that a Promise has a then method
                _locale = persistedLocale;
            }
        }
        if (!isPromise)
            callSubscribers(_locale);
        return _locale;
    }), 2), locale = _b[0], _setLocale = _b[1];
    exports.I18NRef.current.locale = locale;
    i18n.setLocale(locale);
    var setLocale = react_1.default.useCallback(function (newLocale) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            setIsSettingsLocale(true);
            exports.I18NRef.current.locale = newLocale;
            callSubscribers(newLocale);
            _setLocale(newLocale);
            (_a = persistor === null || persistor === void 0 ? void 0 : persistor.setLocale) === null || _a === void 0 ? void 0 : _a.call(persistor, newLocale);
            setTimeout(function () {
                setIsSettingsLocale(false);
            });
            return [2 /*return*/];
        });
    }); }, [callSubscribers]);
    exports.I18NRef.current.setLocale = setLocale;
    (0, utils_1.onMount)(function () {
        if (persistor) {
            var persistedLocale = persistor.getLocale();
            // @ts-expect-error - TS doesn't know that a Promise has a then method
            var isPromise = persistedLocale instanceof Promise || TypeGuards.isFunction(persistedLocale.then);
            if (isPromise) {
                // @ts-expect-error - TS doesn't know that a Promise has a then method
                persistedLocale.then(function (newLocale) {
                    setLocale(newLocale || initialLocale);
                });
            }
        }
    });
    var t = react_1.default.useCallback(function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return i18n.t.apply(i18n, __spreadArray(__spreadArray([key], __read(args), false), [locale], false));
    }, [locale]);
    exports.I18NRef.current.t = t;
    return (react_1.default.createElement(exports.I18NContext.Provider, { value: {
            t: t,
            locale: locale,
            setLocale: setLocale,
            subscribe: subscribe,
            isSettingLocale: isSettingLocale,
        } }, children));
};
exports.I18NProvider = I18NProvider;
var useI18N = function () {
    var ctx = react_1.default.useContext(exports.I18NContext);
    if (!ctx) {
        return {
            locale: 'en',
            setLocale: function () { },
            t: function (key) { return key; },
            isSettingLocale: false,
        };
    }
    return ctx;
};
exports.useI18N = useI18N;
