import { ActionIcon } from '@/components'
import { capitalize } from '@codeleap/common'
import { APIClient } from '@/services'
import { createStyles } from '@codeleap/styles'

type SocialButtonProps = {
  platform: APIClient.Session.SocialProviderTypes
  handleSocial: (args: string) => void
}

export const SocialButton = ({ platform, handleSocial }: SocialButtonProps) => {
  const _platform = capitalize(platform)

  const platformButtonStyle = styles?.[`button${_platform}`]

  return (
    <ActionIcon
      style={['originalColor', styles.button, platformButtonStyle]}
      icon={platform}
      onPress={() => handleSocial(platform)}
      debugName={`${platform} social login button`}
      iconProps={{
        color: platformButtonStyle?.color,
      }}
    />
  )
}

const BUTTON_HEIGHT = 48
const BORDER_WIDTH = 1
const FACEBOOK_ICON_COLOR = '#1877F2'

const styles = createStyles((theme) => ({
  button: {
    flex: 1,
    height: BUTTON_HEIGHT,
    ...theme.spacing.padding(2),
    borderRadius: theme.borderRadius.small,
    backgroundColor: theme.colors.transparent,
    ...theme.presets.center,
    transition: 'background 0.2s',

    '&:hover': {
      backgroundColor: theme.colors.neutral2,
    },
  },
  buttonGoogle: {
    ...theme.border({ color: theme.colors.neutral5, width: BORDER_WIDTH }),
  },
  buttonFacebook: {
    color: FACEBOOK_ICON_COLOR,
    ...theme.border({ color: FACEBOOK_ICON_COLOR, width: BORDER_WIDTH }),
  },
  buttonApple: {
    color: theme.colors.neutral10,
    ...theme.border({ color: theme.colors.neutral10, width: BORDER_WIDTH }),
  },
}))
