"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentrySeverityMap = void 0;
exports.SentrySeverityMap = {
    debug: 'debug',
    error: 'error',
    info: 'info',
    log: 'log',
    warn: 'warning',
    silent: 'log',
};
