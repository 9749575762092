"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createSwitchStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createSwitchStyle(function () { return ({ wrapper: styles }); }); });
exports.SwitchStyles = __assign(__assign({}, presets), { default: createSwitchStyle(function (theme) { return ({
        wrapper: {},
        inputWrapper: __assign(__assign({}, theme.presets.row), theme.presets.alignCenter),
        label: __assign({}, theme.spacing.marginLeft(0.5)),
        input: {
            color: theme.colors.white,
            backgroundColor: theme.colors.gray,
        },
        'input:on': {
            color: theme.colors.primary,
            backgroundColor: theme.colors.gray,
        },
        error: {
            color: theme.colors.negative,
        },
    }); }) });
