"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ellipsis = exports.humanizeCamelCase = exports.isLowercase = exports.isUppercase = exports.capitalize = exports.stringiparse = exports.singleLine = void 0;
function singleLine(text) {
    return text === null || text === void 0 ? void 0 : text.replace(/\n/g, ' ');
}
exports.singleLine = singleLine;
function stringiparse(string) {
    return JSON.parse(JSON.stringify(string));
}
exports.stringiparse = stringiparse;
function capitalize(str, reverse) {
    if (reverse === void 0) { reverse = false; }
    if (!str.length)
        return str;
    var firstChar = reverse ? str[0].toLowerCase() : str[0].toUpperCase();
    return firstChar + str.substring(1);
}
exports.capitalize = capitalize;
function isUppercase(char) {
    return /[A-Z]|[\u0080-\u024F]/.test(char) && char.toUpperCase() === char;
}
exports.isUppercase = isUppercase;
function isLowercase(char) {
    return !isUppercase(char);
}
exports.isLowercase = isLowercase;
function humanizeCamelCase(str) {
    var characters = [];
    var previousCharacter = '';
    str.split('').forEach(function (char, idx) {
        if (idx === 0) {
            characters.push(char.toUpperCase());
        }
        else {
            if (isUppercase(char) && isLowercase(previousCharacter)) {
                characters.push(" ".concat(char));
            }
            else {
                characters.push(char);
            }
        }
        previousCharacter = char;
    });
    return characters.join('');
}
exports.humanizeCamelCase = humanizeCamelCase;
function ellipsis(str, maxLen) {
    if (str.length - 3 > maxLen) {
        return str.slice(0, maxLen - 3) + '...';
    }
    return str;
}
exports.ellipsis = ellipsis;
