"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFilePathData = void 0;
var separators = /[\\\/]+/;
function parseFilePathData(path) {
    var parts = path.split(separators);
    var lastPart = parts[parts.length - 1];
    var fileName = lastPart;
    var ext = '';
    if (lastPart.includes('.')) {
        var dotIdx = fileName.lastIndexOf('.');
        fileName = fileName.substring(0, dotIdx);
        ext = lastPart.substring(dotIdx + 1);
    }
    return {
        path: parts.slice(0, -1).join('/'),
        extension: ext,
        name: fileName,
    };
}
exports.parseFilePathData = parseFilePathData;
