"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInstance = exports.is = exports.isNil = exports.isNull = exports.isUndefined = exports.isArray = exports.isConstructor = exports.isFunction = exports.isBoolean = exports.isObject = exports.isString = exports.isNumber = void 0;
function isNumber(x) {
    return typeof x === 'number';
}
exports.isNumber = isNumber;
function isString(x) {
    return typeof x === 'string';
}
exports.isString = isString;
function isObject(x) {
    return typeof x === 'object';
}
exports.isObject = isObject;
function isBoolean(x) {
    return typeof x === 'boolean';
}
exports.isBoolean = isBoolean;
function isFunction(x) {
    return typeof x === 'function';
}
exports.isFunction = isFunction;
function isConstructor(x) {
    return typeof x === 'function';
}
exports.isConstructor = isConstructor;
function isArray(x) {
    return Array.isArray(x);
}
exports.isArray = isArray;
function isUndefined(x) {
    return typeof x === 'undefined';
}
exports.isUndefined = isUndefined;
function isNull(x) {
    return x === null;
}
exports.isNull = isNull;
function isNil(x) {
    return isNull(x) || isUndefined(x);
}
exports.isNil = isNil;
function is(x, Enum) {
    return Enum.includes(x);
}
exports.is = is;
function isInstance(x, cls) {
    return x instanceof cls;
}
exports.isInstance = isInstance;
