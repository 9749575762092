"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverlayStyles = void 0;
var utils_1 = require("../../../utils");
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createOverlayStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createOverlayStyle(function () { return ({ wrapper: styles }); }); });
exports.OverlayStyles = __assign(__assign({}, presets), { default: createOverlayStyle(function (theme) { return ({
        wrapper: __assign(__assign({ top: 0, left: 0, right: 0, bottom: 0, position: theme.IsBrowser ? 'fixed' : 'absolute', backgroundColor: theme.colors.black }, (0, utils_1.optionalObject)(theme.IsBrowser, { visibility: 'hidden' }, {})), { opacity: 0 }),
        'wrapper:visible': __assign({ opacity: 0.5 }, (0, utils_1.optionalObject)(theme.IsBrowser, { visibility: 'visible' }, {})),
        closeButton: {
            marginLeft: 'auto',
        },
        header: __assign(__assign({}, theme.presets.row), theme.presets.justifySpaceBetween),
    }); }) });
