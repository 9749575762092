"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebounce = void 0;
var react_1 = require("react");
function useDebounce(value, debounce) {
    var _a = __read((0, react_1.useState)(value), 2), debouncedValue = _a[0], setDebouncedValue = _a[1];
    var timeoutRef = (0, react_1.useRef)(null);
    var reset = function () {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };
    (0, react_1.useEffect)(function () {
        timeoutRef.current = setTimeout(function () {
            setDebouncedValue(value);
        }, debounce);
        return reset;
    }, [value]);
    return [debouncedValue, reset];
}
exports.useDebounce = useDebounce;
