"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spacingVariants = exports.accessors = void 0;
exports.accessors = ['screenSize'];
exports.spacingVariants = [
    'Vertical',
    'Horizontal',
    'Bottom',
    'Top',
    'Left',
    'Right',
    '',
];
