"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permission = void 0;
var SCOPE = 'Permissions';
var Permission = /** @class */ (function () {
    function Permission(actions, name) {
        if (name === void 0) { name = ''; }
        this.actions = actions;
        this.name = name;
        this.actions = actions;
        this.shouldAsk = true;
        this.status = 'pending';
    }
    Permission.prototype.ask = function () {
        return __awaiter(this, void 0, void 0, function () {
            var newState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.actions.onAsk()];
                    case 1:
                        newState = _a.sent();
                        this.actions.log("Request for permission ".concat(this.name, " returned"), newState, SCOPE);
                        this.status = newState;
                        if (newState === 'blocked') {
                            this.shouldAsk = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Permission.prototype.check = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var _options, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _options = __assign({ askOnPending: true, askOnDenied: false, ask: true }, options);
                        _a = this;
                        return [4 /*yield*/, this.actions.onCheck()];
                    case 1:
                        _a.status = _c.sent();
                        this.actions.log("Check for permission ".concat(this.name, " returned"), this.status, SCOPE);
                        if (!_options.ask)
                            return [2 /*return*/];
                        _b = this.status;
                        switch (_b) {
                            case 'denied': return [3 /*break*/, 2];
                            case 'pending': return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 8];
                    case 2:
                        if (!_options.askOnDenied) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.ask()];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4: return [3 /*break*/, 8];
                    case 5:
                        if (!_options.askOnPending) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.ask()];
                    case 6:
                        _c.sent();
                        _c.label = 7;
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(Permission.prototype, "isGranted", {
        get: function () {
            return this.status === 'granted';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Permission.prototype, "isDenied", {
        get: function () {
            return this.status === 'denied';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Permission.prototype, "isPending", {
        get: function () {
            return this.status === 'pending';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Permission.prototype, "isBlocked", {
        get: function () {
            return this.status === 'blocked';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Permission.prototype, "isUnavailable", {
        get: function () {
            return this.status === 'unavailable';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Permission.prototype, "isLimited", {
        get: function () {
            return this.status === 'limited';
        },
        enumerable: false,
        configurable: true
    });
    return Permission;
}());
exports.Permission = Permission;
