import { Button, View, Text } from '@/components'
import { React } from '@/app'
import { ReactStateProps, useState } from '@codeleap/common'
import { Widget } from '@/utils'

type TTextInputWidget = Widget<
  ReactStateProps<'multiline', boolean>
>

export const TextInputWidget: TTextInputWidget = {
  Control: ({ setMultiline, label, args, onVariantsChange }) => {
    return (
      <>
        <View style={['row', 'justifySpaceBetween', 'alignCenter']}>
          <Text text={label}/>
        </View>

        <View style={['gap:1', 'wrap']}>
          <Button
            text='Single'
            debugName={`TextInput control`}
            onPress={() => {
              setMultiline(false)
              args?.onValueChange?.()
              onVariantsChange?.()
            }}
          />
          <Button
            text='Multiline'
            debugName={`TextInput control`}
            onPress={() => {
              setMultiline(true)
              args?.onValueChange?.()
              onVariantsChange?.()
            }}
          />
        </View>
      </>
    )
  },
  handle: () => {
    const [multiline, setMultiline] = useState(false)
    return { multiline, setMultiline }
  },
}
