"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToggle = void 0;
var react_1 = require("react");
function useToggle(options, initial) {
    var _a = __read((0, react_1.useState)(initial), 2), value = _a[0], setValue = _a[1];
    function toggleOrSetValue(newValue) {
        var v = newValue || (value === options[0] ? options[1] : options[0]);
        setValue(v);
    }
    return [value, toggleOrSetValue];
}
exports.useToggle = useToggle;
