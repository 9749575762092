"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = exports.LoggerAnalytics = exports.LoggerTypes = void 0;
var utils_1 = require("../../utils");
var Analytics_1 = require("./Analytics");
var Sentry_1 = require("./Sentry");
var Slack_1 = require("./Slack");
exports.LoggerTypes = __importStar(require("./types"));
exports.LoggerAnalytics = __importStar(require("./Analytics"));
var util_1 = require("util");
var logLevels = ['debug', 'info', 'log', 'warn', 'error'];
var emptyFunction = function () { };
var hollowAnalytics = new Analytics_1.Analytics({
    init: emptyFunction,
    onEvent: emptyFunction,
    onInteraction: emptyFunction,
    prepareData: function () { return ({}); },
}, {});
/**
 * [[include:Logger.md]]
 */
var Logger = exports.Logger = /** @class */ (function () {
    function Logger(settings, middleware, analytics) {
        var _this = this;
        var _a, _b;
        this.analytics = analytics;
        this.middleware = [];
        this.logToTerminal = function (logArgs) {
            var _a, _b;
            var logType = logArgs.logType, args = logArgs.args, color = logArgs.color;
            if (_this.settings.Logger.Level === 'silent')
                return;
            var shouldLog = utils_1.TypeGuards.isString(_this.settings.Logger.Level) ?
                logLevels.indexOf(logType) >=
                    logLevels.indexOf(_this.settings.Logger.Level) : _this.settings.Logger.Level.includes(logType);
            if (!shouldLog)
                return;
            var content = Logger.formatContent(logArgs);
            if (_this.settings.Environment.IsDev) {
                var deviceId = ((_a = _this.settings.Logger) === null || _a === void 0 ? void 0 : _a.DeviceIdentifier) ?
                    "[".concat(_this.settings.Logger.DeviceIdentifier, "]") : '';
                var stringify = (_b = _this.settings.Logger) === null || _b === void 0 ? void 0 : _b.StringifyObjects;
                _this.middleware.forEach(function (m) { return m(logArgs, content); });
                Logger.coloredLog({
                    logType: logType,
                    args: args,
                    color: color,
                    deviceIdentifier: deviceId,
                    stringify: stringify,
                });
            }
            if (!_this.settings.Environment.IsDev || _this.settings.Logger.alwaysSendToSentry) {
                try {
                    _this.middleware.forEach(function (m) { return m(logArgs, content); });
                    if (['info', 'log'].includes(logType)) {
                        _this.sentry.captureBreadcrumb(logType, content);
                    }
                    if (['error'].includes(logType)) {
                        _this.sentry.sendLog((args === null || args === void 0 ? void 0 : args[1]) || (args === null || args === void 0 ? void 0 : args[0]));
                    }
                }
                catch (e) {
                    // Nothing
                }
            }
        };
        this.settings = settings;
        this.middleware = middleware || [];
        if (settings.Logger.isMain) {
            Logger.settings = settings;
        }
        if ((_b = (_a = settings === null || settings === void 0 ? void 0 : settings.Logger) === null || _a === void 0 ? void 0 : _a.IgnoreWarnings) === null || _b === void 0 ? void 0 : _b.length) {
            var newConsole_1 = function (args, oldConsole) {
                var shouldIgnore = typeof args[0] === 'string' &&
                    settings.Logger.IgnoreWarnings.some(function (ignoredWarning) { return args.join(' ').includes(ignoredWarning); });
                if (shouldIgnore)
                    return;
                else
                    return oldConsole.apply(console, args);
            };
            var consoles = ['log', 'warn', 'error'];
            consoles.forEach(function (t) {
                var tmp = console[t];
                console[t] = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return newConsole_1(args, tmp);
                };
            });
        }
        this.sentry = new Sentry_1.SentryService(settings);
        this.slack = new Slack_1.SlackService(settings);
        if (!analytics) {
            this.analytics = hollowAnalytics;
        }
        this.analytics.onError(function (err) {
            _this.logToTerminal({
                logType: 'error',
                args: ['Error on analytics event', err, 'Internal'],
            });
        });
    }
    Logger.formatContent = function (logArgs) {
        var _a, _b;
        var _c, _d;
        var logType = logArgs.logType, content = logArgs.args, deviceId = logArgs.deviceIdentifier, stringify = logArgs.stringify, logKeys = (_a = logArgs.logKeys, _a === void 0 ? true : _a);
        var descriptionOrValue = (_b = __read(content, 3), _b[0]), value = _b[1], category = _b[2];
        var nArgs = content.length;
        var logContent = content;
        var logValue = nArgs === 1 ? descriptionOrValue : value;
        var shouldStringify = stringify && !!logValue && utils_1.TypeGuards.isObject(logValue) && !(logValue instanceof Error);
        var inspectOptions = ((_d = (_c = Logger === null || Logger === void 0 ? void 0 : Logger.settings) === null || _c === void 0 ? void 0 : _c.Logger) === null || _d === void 0 ? void 0 : _d.inspect) || {};
        // @ts-expect-error interface merging sucks
        var displayValue = shouldStringify ? (0, util_1.inspect)(logValue, __assign({ depth: 5, showHidden: true }, inspectOptions)) : logValue;
        if (nArgs === 3) {
            logContent = [
                "(".concat(category, ") ").concat(descriptionOrValue).concat(displayValue ? ' ->' : ''),
                displayValue,
            ];
        }
        if (nArgs === 2) {
            logContent = [
                "".concat(descriptionOrValue).concat(displayValue ? ' ->' : ''),
                displayValue,
            ];
        }
        if (nArgs === 1) {
            var isObj = typeof descriptionOrValue === 'object' && !(descriptionOrValue instanceof Error);
            var keys = isObj ? Object.keys(descriptionOrValue) : null;
            var title = isObj && keys.length && logKeys ?
                "".concat(keys.filter(function (i) { return !!i; }).slice(0, 3).join(', ')).concat(keys.length > 3 ? '...' : '', " ->")
                : null;
            if (title) {
                logContent = [
                    title,
                    displayValue,
                ];
            }
            else {
                logContent = [
                    displayValue,
                ];
            }
        }
        return logContent;
    };
    Logger.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.logToTerminal({
            args: args,
            logType: 'info',
        });
    };
    Logger.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.logToTerminal({
            args: args,
            logType: 'error',
        });
    };
    Logger.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.logToTerminal({
            args: args,
            logType: 'warn',
        });
    };
    Logger.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.logToTerminal({
            args: args,
            logType: 'log',
        });
    };
    Logger.prototype.debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this.logToTerminal({
            args: args,
            logType: 'debug',
        });
    };
    Logger.coloredLog = function (logArgs) {
        var logType = logArgs.logType, content = logArgs.args, deviceId = logArgs.deviceIdentifier, stringify = logArgs.stringify;
        var logContent = Logger.formatContent(logArgs);
        var displayLog = logType === 'error' ? 'warn' : logType;
        console[displayLog].apply(console, __spreadArray([deviceId], __read(logContent), false));
        return logContent;
    };
    return Logger;
}());
