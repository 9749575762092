"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUncontrolled = void 0;
var react_1 = require("react");
function useUncontrolled(_a) {
    var value = _a.value, defaultValue = _a.defaultValue, finalValue = _a.finalValue, rule = _a.rule, onChange = _a.onChange, onValueUpdate = _a.onValueUpdate;
    // determine, whether new props indicate controlled state
    var shouldBeControlled = rule(value);
    // initialize state
    var modeRef = (0, react_1.useRef)('initial');
    var initialValue = rule(defaultValue) ? defaultValue : finalValue;
    var _b = __read((0, react_1.useState)(initialValue), 2), uncontrolledValue = _b[0], setUncontrolledValue = _b[1];
    // compute effective value
    var effectiveValue = shouldBeControlled ? value : uncontrolledValue;
    if (!shouldBeControlled && modeRef.current === 'controlled') {
        // We are transitioning from controlled to uncontrolled
        // this transition is special as it happens when clearing out
        // the input using "invalid" value (typically null or undefined).
        //
        // Since the value is invalid, doing nothing would mean just
        // transitioning to uncontrolled state and using whatever value
        // it currently holds which is likely not the behavior
        // user expects, so lets change the state to finalValue.
        //
        // The value will be propagated to internal state by useEffect below.
        effectiveValue = finalValue;
    }
    modeRef.current = shouldBeControlled ? 'controlled' : 'uncontrolled';
    var mode = modeRef.current;
    var handleChange = function (nextValue) {
        typeof onChange === 'function' && onChange(nextValue);
        // Controlled input only triggers onChange event and expects
        // the controller to propagate new value back.
        if (mode === 'uncontrolled') {
            setUncontrolledValue(nextValue);
        }
    };
    (0, react_1.useEffect)(function () {
        if (mode === 'uncontrolled') {
            setUncontrolledValue(effectiveValue);
        }
        typeof onValueUpdate === 'function' && onValueUpdate(effectiveValue);
    }, [mode, effectiveValue]);
    return [effectiveValue, handleChange, modeRef.current];
}
exports.useUncontrolled = useUncontrolled;
