"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalStyles = void 0;
var Text_1 = require("./Text");
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createModalStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createModalStyle(function () { return ({ wrapper: styles }); }); });
var transitionDuration = '0.3s';
exports.ModalStyles = __assign(__assign({}, presets), { default: createModalStyle(function (theme) { return ({
        wrapper: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            visibility: 'hidden',
            zIndex: 2,
        },
        box: __assign({ background: 'white', alignSelf: 'center', flexDirection: 'column' }, theme.spacing.padding(2)),
        overlay: {
            zIndex: -1,
            transition: "opacity ".concat(transitionDuration, " ease"),
        },
        body: {
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto',
        },
        header: __assign({ background: 'transparent', color: theme.colors.primary }, theme.presets.justifySpaceBetween),
        footer: {
            background: 'transparent',
        },
        title: __assign(__assign({}, (0, Text_1.assignTextStyle)('h3')(theme).text), theme.spacing.marginBottom(1)),
    }); }), roundish: createModalStyle(function (theme) { return ({
        box: {
            borderRadius: theme.borderRadius.small,
        },
    }); }), dynamicHandler: createModalStyle(function (theme, variant) {
        var e_1, _a;
        var styles = {};
        try {
            for (var _b = __values(variant.split(';')), _c = _b.next(); !_c.done; _c = _b.next()) {
                var style = _c.value;
                var _d = __read(style.split('-'), 2), shorthand = _d[0], value = _d[1];
                var property = shorthand === 'w' ? 'width' : 'height';
                styles[property] = value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return {
            box: styles,
        };
    }, { dynamic: true }) });
