import { React } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { onUpdate } from '@codeleap/common'
import { Icon, Overlay, View, ActivityIndicator } from '@/components'
import { Logo } from './Logo'
import { useAnimatedStyle } from '@codeleap/web'
import { createStyles } from '@codeleap/styles'

const indicatorSize = 60

export const AppStatusOverlay = () => {
  const status = useAppSelector((store) => store.AppStatus.status)

  onUpdate(() => {
    if (status === 'done') {
      setTimeout(() => {
        AppStatus.set('idle')
      }, 2000)
    }
  }, [status])

  const visibilityStyle = React.useCallback((appStatus) => {
    const isStatusVisible = status === appStatus

    return ({
      transform: `scale(${isStatusVisible ? 1 : 0})`,
      transition: 'transform 0.3s ease',
    })
  }, [status])

  const visibleOverlay = ['loading', 'done'].includes(status)

  const hide = ['blank', 'idle'].includes(status)

  const logoAnimation = useAnimatedStyle(() => ({
    scale: status == 'splash' ? 1 : 0.2,
    opacity: status == 'splash' ? 1 : 0.5,
    transition: {
      duration: .15,
    },
  }), [status])

  return (
    <>
      <Overlay debugName='appStatus' visible={visibleOverlay} style={styles.overlayWrapper} />

      <View style={[styles.wrapper, { pointerEvents: hide ? 'none' : 'auto' }]}>
        <View style={styles.overlay}>
          <View style={[styles.container, visibilityStyle('done')]}>
            <Icon
              debugName='AppStatus:done'
              name='check'
              style={['primary3', 'size:4', visibilityStyle('done')]}
            />
          </View>
        </View>

        <View style={styles.overlay}>
          <View style={[styles.container, visibilityStyle('loading')]}>
            <ActivityIndicator debugName='AppStatus:indicator' style={visibilityStyle('loading')} />
          </View>
        </View>
      </View>

      <View style={[styles.splashWrapper, { opacity: status === 'splash' ? 1 : 0 }]}>
        <Logo
          debugName='AppStatus:splash'
          style={'splash'}
          responsive={false}
          animated
          component={View}
          animatedProps={logoAnimation}
        />
      </View>
    </>
  )
}

const styles = createStyles((theme) => ({
  overlay: {
    ...theme.presets.full,
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.center,
  },
  container: {
    width: indicatorSize,
    height: indicatorSize,
    backgroundColor: theme.colors.neutral1,
    borderRadius: theme.borderRadius.rounded,
    ...theme.presets.center,
  },
  wrapper: {
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 200,
    pointerEvents: 'none',
    transition: 'transform 0.3s ease',
  },
  splashWrapper: {
    display: 'flex',
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 200,
    pointerEvents: 'none',
    transition: 'all 0.3s ease',
    backgroundColor: theme.colors.primary1,
  },
  overlayWrapper: {
    zIndex: 199,
  },
}))
