"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidate = void 0;
var react_1 = require("react");
var utils_1 = require("../../utils");
var __1 = require("../..");
var emptyValues = ['', null, undefined];
function useValidate(value, validator) {
    var isEmpty = emptyValues.includes(value);
    var _a = __read((0, react_1.useState)(''), 2), _message = _a[0], setMessage = _a[1];
    var _b = __read((0, react_1.useState)(true), 2), isValid = _b[0], setIsValid = _b[1];
    var updateErrorOnChange = (0, react_1.useRef)(false);
    var _validator = (0, react_1.useMemo)(function () { return (0, __1.getValidator)(validator); }, [validator]);
    (0, utils_1.onUpdate)(function () {
        if (!updateErrorOnChange.current || !_validator)
            return;
        var _a = _validator(value, {}), valid = _a.valid, message = _a.message;
        setIsValid(valid);
        setMessage(message);
    }, [value]);
    return {
        onInputBlurred: function () {
            if (!_validator)
                return;
            updateErrorOnChange.current = false;
            var _a = _validator(value, {}), valid = _a.valid, message = _a.message;
            setIsValid(valid);
            setMessage(message);
        },
        onInputFocused: function () {
            if (isValid)
                return;
            updateErrorOnChange.current = true;
        },
        message: _message,
        isValid: isValid,
        showError: !isValid && !isEmpty,
        error: {
            message: _message,
        },
    };
}
exports.useValidate = useValidate;
