"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInputStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var utils_1 = require("../../../utils");
var Text_1 = require("./Text");
var createTextInputStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createTextInputStyle(function () { return ({ wrapper: styles }); }); });
exports.TextInputStyles = __assign(__assign({}, presets), { default: createTextInputStyle(function (theme) { return ({
        textField: __assign(__assign(__assign({}, (theme.IsBrowser
            ? {
                outline: 'none',
                border: 'none',
                caretColor: theme.colors.primary,
            }
            : __assign(__assign({}, theme.spacing.padding(0)), theme.spacing.paddingHorizontal(1)))), (0, Text_1.assignTextStyle)('p1')(theme).text), { minWidth: 1, backgroundColor: 'transparent', flex: 1 }),
        placeholder: {
            color: theme.colors.lightGray,
        },
        selection: {
            color: theme.colors.primary,
        },
        wrapper: __assign({ display: 'flex', flexDirection: 'column' }, (0, utils_1.optionalObject)(theme.IsBrowser, {
            '*': {
                transition: 'all 0.2s ease',
            },
        }, {})),
        innerWrapper: __assign(__assign(__assign(__assign(__assign({}, theme.spacing.paddingVertical(0.5)), theme.spacing.paddingHorizontal(1)), theme.presets.row), theme.border.neutral(1)), { display: 'flex', alignItems: 'center' }),
        'icon:focus': {
            color: theme.colors.primary,
        },
        label: __assign(__assign({}, theme.spacing.marginBottom(1)), (0, Text_1.assignTextStyle)('h5')(theme).text),
        icon: {
            size: 20,
            color: theme.colors.neutral,
        },
        leftIcon: __assign(__assign({}, theme.spacing.marginRight(1)), { size: 20 }),
        rightIcon: __assign(__assign({}, theme.spacing.marginLeft(1)), { size: 20 }),
        error: __assign({ color: theme.colors.negative }, theme.spacing.marginTop(0.5)),
        'icon:error': {
            color: theme.colors.negative,
        },
        'label:error': {
            color: theme.colors.negative,
        },
        'textField:error': __assign({}, (0, utils_1.optionalObject)(theme.IsBrowser, {
            caretColor: theme.colors.negative,
        }, {})),
        'innerWrapper:error': __assign({}, theme.border.negative(1)),
        'innerWrapper:focus': __assign({}, theme.border.primary(1)),
        requiredAsterisk: {
            color: theme.colors.negative,
        },
        labelWrapper: __assign({}, theme.presets.row),
    }); }), line: createTextInputStyle(function (theme) { return ({
        innerWrapper: __assign({}, theme.border.neutral({ width: 1, directions: ['bottom'] })),
    }); }), box: createTextInputStyle(function (theme) { return ({
        innerWrapper: __assign({}, theme.border.neutral(1)),
    }); }), pill: createTextInputStyle(function (theme) { return ({
        innerWrapper: __assign(__assign({}, theme.border.neutral(1)), { borderRadius: 15 }),
    }); }) });
