import { AppImages, StyleSheets, StyleRegistry } from '@/app'
import { Image, Touchable, ImageProps } from '@/components'
import { useMediaQueryDown, useToggleDevMode } from '@/utils'
import { TouchableProps, useStylesFor, ViewProps } from '@codeleap/web'
import { LogoComposition } from '../app/stylesheets/Logo'
import { StyledComponent, StyledProp, Breakpoint } from '@codeleap/styles'

export type LogoProps =
  Omit<ViewProps, 'style'> &
  Omit<TouchableProps, 'style'> &
  {
    style?: StyledProp<LogoComposition>
    switchServerOnPress?: boolean
    breakpoint?: Breakpoint
    responsive?: boolean
    image?: ImageProps['source']
  }

export const Logo: StyledComponent<typeof StyleSheets.LogoStyles, LogoProps> = (props) => {
  const {
    style,
    switchServerOnPress,
    image = null,
    breakpoint = 'tabletSmall',
    responsive = true,
    ...rest
  } = props

  const styles = useStylesFor(Logo.styleRegistryName, style)

  const isSmall = useMediaQueryDown(breakpoint)

  const { onPress } = useToggleDevMode()

  const logoImage = responsive && isSmall ? AppImages.LogoTemplateMobile : AppImages.LogoTemplate

  return (
    <Touchable
      debugName={'Logo button'}
      {...rest}
      onPress={switchServerOnPress ? onPress : props.onPress}
      style={styles.wrapper}
    >
      <Image source={image ?? logoImage} style={styles.image} alt='Logo' />
    </Touchable>
  )
}

Logo.styleRegistryName = 'Logo'
Logo.elements = ['wrapper', 'image']

StyleRegistry.registerComponent(Logo)
