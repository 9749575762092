"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyPlaceholderStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var Text_1 = require("./Text");
var createEmptyPlaceholderStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createEmptyPlaceholderStyle(function () { return ({ wrapper: styles }); }); });
exports.EmptyPlaceholderStyles = __assign(__assign({}, presets), { default: createEmptyPlaceholderStyle(function (theme) { return ({
        wrapper: __assign(__assign({}, theme.presets.center), { minHeight: theme.values.height / 2, height: '100%', flex: 1 }),
        loaderWrapper: __assign(__assign(__assign({}, theme.spacing.marginVertical(8)), theme.presets.center), theme.presets.flex),
        icon: {
            color: theme.colors.placeholder,
            size: theme.spacing.value(24),
        },
        text: __assign(__assign({}, (0, Text_1.assignTextStyle)('p1')(theme).text), theme.presets.textCenter),
    }); }), compact: createEmptyPlaceholderStyle(function (theme) { return ({
        wrapper: __assign(__assign({}, theme.spacing.marginVertical(0)), { marginBottom: theme.spacing.value(6) }),
    }); }), absolute: createEmptyPlaceholderStyle(function (theme) { return ({
        'wrapper:loading': __assign(__assign(__assign({}, theme.presets.absolute), theme.presets.whole), { backgroundColor: theme.colors.background }),
        wrapper: __assign(__assign(__assign({}, theme.presets.absolute), theme.presets.whole), { backgroundColor: theme.colors.background }),
    }); }) });
