"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var Text_1 = require("./Text");
var createSelectStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createSelectStyle(function () { return ({ wrapper: styles }); }); });
exports.SelectStyles = __assign(__assign({}, presets), { default: createSelectStyle(function (theme) { return ({
        wrapper: {
            position: 'relative',
            width: 'max-content',
            flexDirection: 'column',
            ':after': {
                content: '""',
                position: 'absolute',
                bottom: '1rem',
                right: '0.5rem',
                width: '0.5rem',
                height: '0.5rem',
                borderBottom: '2px solid',
                borderLeft: '2px solid',
                borderColor: theme.colors.secondary,
                transform: 'rotate(-45deg)',
                pointerEvents: 'none',
            },
        },
        select: __assign(__assign(__assign(__assign({}, (0, Text_1.assignTextStyle)('p1')(theme).text), { minWidth: '100%', display: 'flex', cursor: 'pointer', border: 'none', outline: 'none', webkitAppearance: 'none', mozAppearance: 'none', appearance: 'none', backgroundColor: theme.colors.primary, color: theme.colors.secondary }), theme.spacing.padding(1)), theme.spacing.paddingRight(3)),
        label: __assign(__assign(__assign(__assign({}, (0, Text_1.assignTextStyle)('p1')(theme).text), { color: theme.colors.secondary }), theme.spacing.padding(1)), theme.spacing.paddingLeft(0)),
    }); }) });
