"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityIndicatorStyles = exports.getActivityIndicatorBaseStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createActivityIndicatorStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createActivityIndicatorStyle(function () { return ({ wrapper: styles }); }); });
var getActivityIndicatorBaseStyles = function (size) {
    var sizes = {
        height: size,
        width: size,
        borderWidth: size * 0.25,
    };
    return {
        wrapper: __assign({ position: 'relative' }, sizes),
        circle: {
            borderRadius: 100,
            position: 'absolute',
            borderStyle: 'solid',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        backCircle: {
            // borderColor: theme.colors.primary,
            minWidth: '100%',
            opacity: 0.5,
        },
        frontCircle: {
            position: 'absolute',
            borderColor: 'transparent',
            // borderTopColor: theme.colors.primary,
        },
    };
};
exports.getActivityIndicatorBaseStyles = getActivityIndicatorBaseStyles;
exports.ActivityIndicatorStyles = __assign(__assign({}, presets), { default: createActivityIndicatorStyle(function (theme) {
        var baseStyles = (0, exports.getActivityIndicatorBaseStyles)(35);
        return __assign(__assign({}, baseStyles), { backCircle: __assign(__assign({}, baseStyles.backCircle), { borderColor: theme.colors.primary }), frontCircle: __assign(__assign({}, baseStyles.frontCircle), { borderTopColor: theme.colors.primary }) });
    }) });
