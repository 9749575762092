"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.throttle = exports.hasFastRefreshed = exports.getNestedStylesByKey = exports.parseSourceUrl = exports.waitFor = exports.matchInitialToColor = exports.imagePathToFileObject = void 0;
var string_1 = require("./string");
function imagePathToFileObject(imagePath) {
    var parts = imagePath ? imagePath.split('.') : '';
    var ext = imagePath ? parts[parts.length - 1].toLowerCase() : '';
    var fileValue = imagePath
        ? {
            uri: imagePath,
            name: 'image_' + imagePath,
            type: "image/".concat(ext),
        }
        : null;
    return fileValue;
}
exports.imagePathToFileObject = imagePathToFileObject;
var letterToColorMap = {
    a: '#7CB9E8',
    b: '#3a9e77',
    c: '#A3C1AD',
    d: '#E1BD27',
    e: '#badc58',
    f: '#db5970',
    g: '#9b8ef1',
    h: '#ffe169',
    i: '#3ea9d1',
    j: '#8aa341',
    k: '#baf2f5',
    l: '#ffa02d',
    m: '#d46830',
    n: '#62ecaa',
    o: '#ffbe50',
    p: '#0078D7',
    q: '#8764B8',
    r: '#52dd64',
    s: '#7edce9',
    t: '#dadd5d',
    u: '#e9b55d',
    v: '#99d669',
    w: '#a3c83a',
    x: '#f28d67',
    y: '#ea82ec',
    z: '#ff8295',
};
function matchInitialToColor(anyString) {
    if (!anyString)
        return '#999999';
    return letterToColorMap[anyString.toLowerCase().charAt(0)] || '#999999';
}
exports.matchInitialToColor = matchInitialToColor;
function waitFor(ms) {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve();
        }, ms);
    });
}
exports.waitFor = waitFor;
function parseSourceUrl(args, Settings) {
    if (!args)
        return null;
    var res = '';
    var address = '';
    if (typeof args === 'string') {
        address = args;
    }
    else {
        address = args.source || args.src || '';
    }
    if (address && address.startsWith('/media/')) {
        var tmp = address.substr(1, address.length);
        res = "".concat(Settings.BaseURL).concat(tmp);
    }
    else if (address) {
        res = address;
    }
    else {
        res = "https://picsum.photos/600?random=".concat(Math.random() * 100);
    }
    return res;
}
exports.parseSourceUrl = parseSourceUrl;
function getNestedStylesByKey(match, variantStyles) {
    var e_1, _a;
    var styles = {};
    try {
        for (var _b = __values(Object.entries(variantStyles)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
            if (key.startsWith(match)) {
                var partName = (0, string_1.capitalize)(key.replace(match, ''), true);
                styles[partName] = value;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return styles;
}
exports.getNestedStylesByKey = getNestedStylesByKey;
function hasFastRefreshed(Settings) {
    var _a;
    if ((_a = Settings === null || Settings === void 0 ? void 0 : Settings.Environment) === null || _a === void 0 ? void 0 : _a.InitTime) {
        var timeFromStartup = (new Date()).getTime() - Settings.Environment.InitTime.getTime();
        // It usually takes less than a seconds (~300ms) from app launch to running this, so if's been more than that we've probably fast refreshed
        var fastRefreshed = Settings.Environment.IsDev && timeFromStartup > 1000;
        return fastRefreshed;
    }
    else {
        console.log('hasFreshRefreshed() => Missing datetime from settings, please include to make this work');
        return undefined;
    }
}
exports.hasFastRefreshed = hasFastRefreshed;
var throttleTimerId = [];
function throttle(func, ref, delay) {
    if (throttleTimerId[ref]) {
        return;
    }
    throttleTimerId[ref] = setTimeout(function () {
        func();
        throttleTimerId[ref] = undefined;
    }, delay);
}
exports.throttle = throttle;
