"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIError = void 0;
var util_1 = require("util");
var APIError = /** @class */ (function (_super) {
    __extends(APIError, _super);
    function APIError(reason, url, axiosError) {
        if (reason === void 0) { reason = 'INVALID_STATUS_CODE'; }
        if (url === void 0) { url = ''; }
        if (axiosError === void 0) { axiosError = null; }
        var _this = this;
        var _a, _b, _c;
        var status = (_a = axiosError === null || axiosError === void 0 ? void 0 : axiosError.response) === null || _a === void 0 ? void 0 : _a.status;
        var data = (_b = axiosError === null || axiosError === void 0 ? void 0 : axiosError.response) === null || _b === void 0 ? void 0 : _b.data;
        var method = (_c = axiosError === null || axiosError === void 0 ? void 0 : axiosError.config) === null || _c === void 0 ? void 0 : _c.method;
        var message = "\n      APIError\n      Reason: ".concat(reason, "\n      Method: ").concat(method, "\n      URL: ").concat(url, " \n      Status Code: ").concat(status, " \n      Response data: ").concat((0, util_1.inspect)(data, {
            depth: 3,
            compact: false,
            breakLength: 80,
        }));
        _this = _super.call(this, message) || this;
        _this.reason = reason;
        _this.url = url;
        _this.method = method;
        _this.status = status;
        _this.data = data;
        _this.axiosError = axiosError;
        return _this;
    }
    return APIError;
}(Error));
exports.APIError = APIError;
