"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var utils_1 = require("../../../utils");
var utils_2 = require("../../../utils");
var createButtonStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createButtonStyle(function () { return ({ wrapper: styles }); }); });
exports.ButtonStyles = __assign(__assign({}, presets), { default: createButtonStyle(function (theme) { return ({
        wrapper: __assign(__assign({ cursor: 'pointer', border: 'none', outline: 'none', display: 'flex', flexDirection: 'row', backgroundColor: theme.colors.primary }, theme.presets.alignCenter), (theme.IsBrowser ?
            {
                '&:hover': {
                    backgroundColor: (0, utils_1.shadeColor)(theme.colors.primary, -30),
                },
            }
            : {})),
        text: {
            flex: 1,
            textAlign: 'center',
        },
        loader: {
            height: 20,
            width: 20,
        },
        loaderFrontCircle: {
            borderTopColor: theme.colors.white,
        },
        'wrapper:disabled': __assign({ backgroundColor: theme.colors.disabled, opacity: 0.9, cursor: 'auto' }, (0, utils_2.optionalObject)(theme.IsBrowser, {
            '&:hover': {
                backgroundColor: theme.colors.disabled,
            },
        }, {})),
        badgeWrapper: __assign({ backgroundColor: theme.colors.negative, position: 'absolute' }, theme.spacing.padding(2.5)),
        badgeText: {
            color: theme.colors.white,
        },
    }); }), negative: createButtonStyle(function (theme) { return ({
        wrapper: {
            backgroundColor: theme.colors.negative,
            '&:hover': {
                backgroundColor: (0, utils_1.shadeColor)(theme.colors.negative, -30),
            },
        },
    }); }), circle: createButtonStyle(function (theme) { return ({
        wrapper: __assign({ borderRadius: 100 }, theme.spacing.padding(1)),
    }); }), pill: createButtonStyle(function (theme) { return ({
        wrapper: __assign(__assign({ borderRadius: theme.borderRadius.medium }, theme.spacing.paddingHorizontal(1)), theme.spacing.paddingVertical(0.5)),
    }); }), icon: createButtonStyle(function (theme) { return ({
        wrapper: __assign(__assign(__assign({ backgroundColor: 'transparent', aspectRatio: 1, display: 'flex' }, theme.presets.center), theme.spacing.padding(0)), (0, utils_2.optionalObject)(theme.IsBrowser, { '&:hover': {
                backgroundColor: 'transparent',
            } }, {})),
        text: {
            flex: 1,
            textAlign: 'center',
        },
        loader: __assign({}, theme.spacing.margin(0)),
        icon: __assign(__assign(__assign({}, theme.spacing.margin(0)), theme.presets.center), { height: null, width: null, color: theme.colors.icon }),
        leftIcon: __assign({}, theme.spacing.marginRight(0)),
        rightIcon: __assign({}, theme.spacing.marginRight(0)),
    }); }) });
