import { IS_SSR, React, Settings } from '@/app'
import { useI18N, useMemo } from '@codeleap/common'
import { Button, Modal, View, Text, Tooltip, Icon } from '@/components'
import { Share, shareOptions } from '@/utils'
import { createStyles } from '@codeleap/styles'

const DefaultIcon = (props) => <Icon {...props} />

export const ShareModal = () => {
  const { visible, data: shareData, toggle } = Share.useShareStore(store => store)

  const { t } = useI18N()

  const content = {
    url: shareData?.url,
    title: shareData?.text,
    description: shareData?.text,
    text: shareData?.text,
    summary: shareData?.text,
    quote: shareData?.title,
    appId: Settings.ApiCredentials.FacebookSDK.AppId,
    separator: ' ',
  }

  const canClipboard = useMemo(() => {
    if (IS_SSR) return

    return !!navigator.clipboard
  }, [])

  const copyLink = React.useCallback(async () => {
    await navigator.clipboard.writeText(content?.url)
  }, [content?.url])

  const options = React.useMemo(() => {
    return Object.keys(shareOptions).map(provider => {
      const option = shareOptions?.[provider]
      const { Action, color } = option ?? {}

      const ProviderIcon = option?.Icon ?? DefaultIcon

      const url = option?.parseUrl?.(content?.url) ?? content?.url

      return (
        <Action
          key={provider + 'share-modal'}
          {...content}
          url={url}
          style={{ ...styles.share, border: `1px solid ${color}` }}
        >
          <View style={['flex', 'fullWidth', 'fullHeight', 'center']}>
            <ProviderIcon name={provider} size={ICON_SIZE} />
          </View>
        </Action>
      )
    })
  }, [content])

  return (
    <Modal
      visible={visible && !!shareData}
      toggle={toggle}
      debugName={'Share Modal'}
      showClose={false}
      style={['centered', 'box:responsiveness']}
    >
      <View style={['column', 'fullWidth', 'alignStart', 'justifyCenter', 'marginBottom:1']}>
        <Text
          style={['h2', 'ellipsis', styles.text]}
          text={shareData?.title ?? t('share.title')}
        />
      </View>

      <View style={['column', 'fullWidth', 'alignStart', 'justifyCenter', 'gap:3']}>
        <Text style={['p1']} text={shareData?.text ?? t('share.description')} />

        <View style={['row', 'wrap', 'gap:1', 'center']}>
          {options}
        </View>

        <View style={['fullWidth', 'row', 'gap:2']}>
          {canClipboard ? (
            <Tooltip
              debugName={'shareModal:tooltip'}
              content={<Text text={t('share.copyMessage')} style={['p2', 'color:neutral10']} />}
              openOnPress
              openOnHover={false}
              side='top'
              closeOnClickOutside
              style={['small', 'success', 'flex', 'overlap']}
            >
              <Button
                debugName='shareModal:clipboard'
                text={t('share.copyText')}
                onPress={copyLink}
                style={['outline', 'fullWidth', 'large', 'pill', 'flex', 'px:4']}
              />
            </Tooltip>
          ) : null}

          <Button
            debugName='shareModal:done'
            text={t('share.modalCancel')}
            onPress={toggle}
            style={['large', 'pill', 'flex', 'fullWidth']}
          />
        </View>
      </View>
    </Modal>
  )
}

const ICON_SIZE = 24

const styles = createStyles((theme) => ({
  share: {
    borderRadius: theme.borderRadius.small,
    overflow: 'hidden',
    ...theme.presets.center,
    ...theme.spacing.paddingHorizontal(4),
    maxHeight: ICON_SIZE * 2,
    height: ICON_SIZE * 2,
  },
  text: {
    maxWidth: '100%',
  },
}))
