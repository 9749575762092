"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarStyles = void 0;
var utils_1 = require("../../../utils");
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createAvatarStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createAvatarStyle(function () { return ({ wrapper: styles }); }); });
exports.AvatarStyles = __assign(__assign({}, presets), { default: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign(__assign(__assign({}, Theme.presets.center), Theme.semiCircle(140)), { overflow: 'hidden', display: 'flex' }), (Theme.IsBrowser
                ? (_a = {},
                    _a[Theme.media.is('small')] = __assign({}, Theme.semiCircle(70)),
                    _a) : {})),
            image: __assign(__assign(__assign(__assign({}, Theme.presets.center), { position: 'relative' }), (Theme.IsBrowser
                ? {
                    objectFit: 'cover',
                    '&:hover': {
                        cursor: 'pointer',
                    }
                }
                : {})), { opacity: 1, flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }),
            text: {
                fontSize: 26,
                color: Theme.colors.white,
            },
            textEdit: __assign({ color: 'white' }, (0, utils_1.optionalObject)(Theme.IsBrowser, {
                position: 'absolute',
                fontWeight: 'bold',
                textDecoration: 'underline',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }, {})),
            editImageIcon: __assign(__assign({}, Theme.presets.center), { width: 150, height: 150 }),
            wrapper: {
                overflow: 'hidden',
                position: 'relative',
                alignItems: 'center',
            },
            fileInput: {
                display: 'none',
            },
            editing: {
                backgroundColor: Theme.colors.black,
                opacity: 0.4,
                zIndex: 9,
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: 30,
                alignItems: 'center',
                justifyContent: 'center',
            },
        });
    }), large: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.semiCircle(200)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.semiCircle(80)),
                _a), {})),
            text: {
                fontSize: 150,
                lineHeight: 160,
            },
        });
    }), medium: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.semiCircle(100)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.semiCircle(60)),
                _a), {})),
            text: {
                fontSize: 60,
                lineHeight: 50,
            },
        });
    }), banner: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.semiCircle(60)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.semiCircle(40)),
                _a), {})),
            text: {
                fontSize: 24,
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100%',
            },
        });
    }), small: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.semiCircle(40)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.semiCircle(30)),
                _a), {})),
            text: {
                fontSize: 20,
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100%',
            },
        });
    }), verySmall: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.semiCircle(16)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.semiCircle(14)),
                _a), {})),
            text: {
                fontSize: 11,
                alignItems: 'center',
                justifyContent: 'center',
            },
        });
    }), largeCircle: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.circle(100)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.circle(60)),
                _a), {})),
        });
    }), midCircle: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.circle(40)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.circle(30)),
                _a), {})),
        });
    }), smallCircle: createAvatarStyle(function (Theme) {
        var _a;
        return ({
            general: __assign(__assign({}, Theme.circle(30)), (0, utils_1.optionalObject)(Theme.IsBrowser, (_a = {},
                _a[Theme.media.is('small')] = __assign({}, Theme.circle(30)),
                _a), {})),
        });
    }), borders: createAvatarStyle(function (Theme) { return ({
        general: {
            border: Theme.border.neutral(1),
        },
    }); }), disabled: createAvatarStyle(function (Theme) { return ({
        general: {
            pointerEvents: 'none',
        },
    }); }) });
