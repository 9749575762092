"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodeleapQueryClient = void 0;
var utils_1 = require("../../utils");
var Crud_1 = require("../Crud");
var CodeleapQueryClient = /** @class */ (function () {
    function CodeleapQueryClient(client) {
        this.client = client;
    }
    CodeleapQueryClient.prototype.queryKey = function (k) {
        var _this = this;
        return {
            key: k,
            getData: function () {
                return _this.client.getQueryData(k);
            },
            setData: function (updater) {
                var _updater = function (prev) {
                    if (utils_1.TypeGuards.isFunction(updater)) {
                        return updater(prev);
                    }
                    return updater;
                };
                return _this.client.setQueryData(k, _updater);
            },
            refresh: function () {
                return _this.client.invalidateQueries({
                    exact: true,
                    queryKey: k,
                    refetchPage: function () { return true; },
                });
            },
        };
    };
    CodeleapQueryClient.prototype.dynamicQueryKey = function (k) {
        var _this = this;
        return {
            key: k,
            getData: function () {
                var params = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    params[_i] = arguments[_i];
                }
                return _this.client.getQueryData(k.apply(void 0, __spreadArray([], __read(params), false)));
            },
            setData: function (params, updater) {
                var _updater = function (prev) {
                    if (utils_1.TypeGuards.isFunction(updater)) {
                        return updater(prev);
                    }
                    return updater;
                };
                return _this.client.setQueryData(k.apply(void 0, __spreadArray([], __read(params), false)), _updater);
            },
            refresh: function () {
                var params = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    params[_i] = arguments[_i];
                }
                return _this.client.invalidateQueries({
                    exact: true,
                    queryKey: k.apply(void 0, __spreadArray([], __read(params), false)),
                    refetchPage: function () { return true; },
                });
            },
        };
    };
    CodeleapQueryClient.prototype.queryManager = function (name, options) {
        // @ts-expect-error
        var m = new Crud_1.QueryManager(__assign({ name: name, queryClient: this.client }, options));
        return m;
    };
    return CodeleapQueryClient;
}());
exports.CodeleapQueryClient = CodeleapQueryClient;
