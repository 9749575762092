import { FileInputRef, Touchable, useStylesFor, View } from '@codeleap/web'
import { StyledProp } from '@codeleap/styles'
import { toolbarOptions } from './ToolbarOptions'
import { Editor } from '@tiptap/react'
import { RefObject } from 'react'
import { useMemo } from '@codeleap/common'
import { ToolbarComposition } from '../../app/stylesheets/Toolbar'
import { StyleRegistry } from '@/app'

export * from './Extensions'

type ToolBarProps = {
  editor: Editor
  fileInputRef?: RefObject<FileInputRef>
  excludeIds?: string[]
  style?: StyledProp<ToolbarComposition>
}

const ToolBarOption = ({ option, editor, styles, fileInputRef, ...props }) => {
  return (
    <Touchable
      debugName={`toggle ${option.id}`}
      onPress={() => option.action({ editor, fileInputRef })}
      {...props}
      style={styles}
    >
      {option.icon}
    </Touchable>
  )
}

export const ToolBar = (props: ToolBarProps) => {
  const { editor, fileInputRef, excludeIds, style } = props

  const styles = useStylesFor(ToolBar.styleRegistryName, style)

  const options = useMemo(() => {
    if (excludeIds) {
      return toolbarOptions.map(group => group.filter(option => !excludeIds.includes(option.id)))
    } else {
      return toolbarOptions
    }
  }, [excludeIds?.length])

  return (
    <View style={styles.wrapper}>
      {options.map((group) => {
        return (
          <View style={styles.toolbarGroup}>
            {group.map((option) => {
              const isActive = option?.isActive?.({ editor }) || editor?.isActive?.(option.id)
              const optionStyles = {
                wrapper: { ...styles.option, ...(isActive && styles['option:active']) },
                'wrapper:disabled': styles['option:disabled'],
              }

              const optionComponentProps = { editor, option, styles: optionStyles, fileInputRef }

              return option?.custom ?
                option.custom({ defaultBtn: (props) => <ToolBarOption {...optionComponentProps} {...props}/>, ...optionComponentProps })
                : <ToolBarOption key={option.id} {...optionComponentProps}/>
            })}
          </View>
        )
      })}
    </View>
  )
}

ToolBar.styleRegistryName = 'ToolBar'
ToolBar.elements = ['wrapper', 'toolbarGroup', 'option']

StyleRegistry.registerComponent(ToolBar)
