import { StyleSheets, navigation, StyleRegistry } from '@/app'
import { PropsOf, TypeGuards } from '@codeleap/common'
import { scrollToElem, stopPropagation } from '@codeleap/web'
import { Link as GatsbyLink } from 'gatsby'
import { Link as GatsbyI18nLink } from 'gatsby-plugin-react-i18next'
import { useStylesFor } from '@codeleap/web'
import { PropsWithVariants, AnyRecord } from '@codeleap/styles'

type GatsbyLinkProps = PropsOf<typeof GatsbyLink>

export type LinkProps<T extends AppRoute> =
  Omit<GatsbyLinkProps, 'to' | 'style'> &
  {
    openNewTab?: boolean
    onScroll?: (to: string) => any
    to?: string
    route?: T
    routeParams?: Record<RouteParams<T>, string>
    text?: string
    type?: 'default' | 'i18n'
    style?: PropsWithVariants<AnyRecord, typeof StyleSheets.LinkStyles>['style']
  }

export function Link<T extends AppRoute>(linkProps: LinkProps<T>) {
  const {
    to: linkTo,
    text,
    route = null,
    routeParams,
    openNewTab,
    onScroll = null,
    children,
    style,
    type = 'default',
    ...props
  } = linkProps

  const styles = useStylesFor(Link.styleRegistryName, style)

  const to = TypeGuards.isString(route) ? navigation.getPathWithParams(route, routeParams) : linkTo

  const isExternal = ['http', 'tel', 'mailto'].some((start) => to?.startsWith(start))

  function handleClick(event: React.MouseEvent) {
    if (to) {
      if (to.startsWith('#')) {
        event.preventDefault()
        stopPropagation(event)
        if (onScroll) {
          onScroll(to)
          return
        }
        scrollToElem(to)
      }
      if (openNewTab) {
        event.preventDefault()
        window?.open?.(to, '_blank')
        return false
      }
    }
  }

  const content = children || text

  if (isExternal) {
    return (
      <a
        href={to}
        onClick={handleClick}
        css={[styles.text, styles.anchor]}
        {...props}
      >
        {content}
      </a>
    )
  }

  const _Link = type === 'default' ? GatsbyLink : GatsbyI18nLink

  return (
    <_Link
      // @ts-ignore
      ref={linkProps.ref}
      to={to}
      onClick={handleClick}
      {...props}
      css={[styles.text, styles.anchor]}
    >
      {content}
    </_Link>
  )
}

Link.styleRegistryName = 'Link'
Link.rootElement = 'anchor'
Link.elements = ['anchor', 'text']

StyleRegistry.registerComponent(Link)
