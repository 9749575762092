"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultVariantFactory = void 0;
var defaultOptions = {
    dynamic: false,
};
function createDefaultVariantFactory() {
    function createVariant(builder, options) {
        if (options === void 0) { options = defaultOptions; }
        if (options.dynamic) {
            return (function () { return builder; });
        }
        return builder;
    }
    return createVariant;
}
exports.createDefaultVariantFactory = createDefaultVariantFactory;
