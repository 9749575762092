"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onUpdate = void 0;
var react_1 = require("react");
var onUpdate = function (func, listeners) {
    if (listeners === void 0) { listeners = []; }
    (0, react_1.useEffect)(function () {
        return func();
    }, listeners);
};
exports.onUpdate = onUpdate;
