"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariantProvider = void 0;
var utils_1 = require("./utils");
var defaults_1 = require("./defaults");
var utils_2 = require("../../utils");
var constants_1 = require("../../constants");
var helpers_1 = require("../helpers");
var SCOPE = 'Styles';
function getThemeWithColorScheme(theme, scheme) {
    return __assign(__assign({}, theme), { colors: theme.colors[scheme] });
}
/**
 * [[include:Variants.md]]
 */
var VariantProvider = /** @class */ (function () {
    function VariantProvider(theme, rootStyleFunction, logger) {
        if (logger === void 0) { logger = constants_1.silentLogger; }
        this.logger = logger;
        // @ts-ignore
        this.colorSchemeListeners = [];
        this.styleSheets = {};
        this.createStylesheet = rootStyleFunction || (function (a) { return a; });
        this.theme = theme;
    }
    VariantProvider.prototype.getDefaultVariants = function (componentName) {
        var _this = this;
        var TransformedVariants = {};
        try {
            if (!componentName) {
                Object.entries(defaults_1.DEFAULT_STYLES).forEach(function (_a) {
                    var _b = __read(_a, 2), component = _b[0], variantsObject = _b[1];
                    TransformedVariants[component] = (0, utils_1.mapVariants)(
                    // @ts-ignore
                    _this.withColorScheme(), variantsObject);
                });
                return TransformedVariants;
            }
            else {
                return (0, utils_1.mapVariants)(this.withColorScheme(), defaults_1.DEFAULT_STYLES[componentName]);
            }
        }
        catch (e) {
            throw new Error("Error on getDefaultVariants ".concat(componentName || 'AllComponents', " ").concat(e));
        }
    };
    VariantProvider.prototype.withColorScheme = function (scheme) {
        // @ts-ignore
        return getThemeWithColorScheme(this.theme, scheme || this.theme.theme);
    };
    VariantProvider.prototype.createComponentStyle = function (styles, staticStyles, useTheme) {
        var _this = this;
        if (staticStyles === void 0) { staticStyles = false; }
        try {
            var isFunction = utils_2.TypeGuards.isFunction(styles);
            if (staticStyles) {
                // @ts-ignore
                var styleObject = isFunction ? styles(this.withColorScheme(useTheme)) : styles;
                var styleMap = (0, utils_2.mapObject)(styleObject, function (_a) {
                    var _b = __read(_a, 2), key = _b[0], value = _b[1];
                    return [
                        key,
                        _this.createStylesheet(value),
                    ];
                });
                return Object.fromEntries(styleMap);
            }
            else {
                if (!isFunction) {
                    throw new Error("\n            createComponentStyle was called with a non function styles argument and staticStyles set to false.\n            Either use a function as the styles argument, or enable staticStyles to get rid of this error.\n          ");
                }
                return styles;
            }
        }
        catch (e) {
            this.logger.error('createComponentStyle', {
                arguments: arguments,
                e: e,
            });
        }
    };
    VariantProvider.prototype.createVariantFactory = function (name) {
        var _this = this;
        if (name === void 0) { name = ''; }
        return function (variant) {
            try {
                if (utils_2.TypeGuards.isFunction(variant)) {
                    var themeGetter = variant;
                    return themeGetter;
                }
                return variant;
            }
            catch (e) {
                _this.logger.error("Error on variant factory for ".concat(name), e, SCOPE);
            }
        };
    };
    VariantProvider.prototype.getStyles = function () {
        var e_1, _a, e_2, _b;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _c = __read(args, 3), styles = _c[0], _d = _c[1], variants = _d.variants, _e = _d.rootElement, rootElement = _e === void 0 ? 'wrapper' : _e, responsiveVariants = _d.responsiveVariants, override = _d.styles, _f = _d.debugName, debugName = _f === void 0 ? '' : _f, size = _d.size, useTheme = _c[2];
        var variantList = (0, utils_1.standardizeVariants)(variants);
        try {
            var computedStyles = {};
            try {
                for (var _g = __values(__spreadArray(['default'], __read(variantList), false)), _h = _g.next(); !_h.done; _h = _g.next()) {
                    var variant = _h.value;
                    computedStyles = (0, utils_1.applyVariants)({
                        computedStyles: computedStyles,
                        rootElement: rootElement,
                        styles: styles,
                        // @ts-ignore
                        theme: this.withColorScheme(useTheme),
                        variantName: variant,
                    });
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_h && !_h.done && (_a = _g.return)) _a.call(_g);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (responsiveVariants) {
                var _loop_1 = function (breakpoint) {
                    var e_3, _j;
                    var responseVariantList = (0, utils_1.standardizeVariants)(responsiveVariants[breakpoint]);
                    var breakPointStyle = {};
                    var mediaQuery = this_1.theme.media.down(breakpoint);
                    try {
                        for (var responseVariantList_1 = (e_3 = void 0, __values(responseVariantList)), responseVariantList_1_1 = responseVariantList_1.next(); !responseVariantList_1_1.done; responseVariantList_1_1 = responseVariantList_1.next()) {
                            var variant = responseVariantList_1_1.value;
                            computedStyles = (0, utils_1.applyVariants)({
                                computedStyles: computedStyles,
                                rootElement: rootElement,
                                styles: styles,
                                // @ts-ignore
                                theme: this_1.withColorScheme(useTheme),
                                variantName: variant,
                                wrapStyle: function (s) {
                                    var _a;
                                    return (_a = {},
                                        _a[mediaQuery] = s,
                                        _a);
                                }
                            });
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (responseVariantList_1_1 && !responseVariantList_1_1.done && (_j = responseVariantList_1.return)) _j.call(responseVariantList_1);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                };
                var this_1 = this;
                for (var breakpoint in responsiveVariants) {
                    _loop_1(breakpoint);
                }
            }
            var styleKeys = (0, utils_2.uniqueArrayByProperty)(__spreadArray(__spreadArray([], __read(Object.keys(computedStyles)), false), __read(Object.keys(override || {})), false), function (a) { return a; });
            var appliableStyles = {};
            try {
                for (var styleKeys_1 = __values(styleKeys), styleKeys_1_1 = styleKeys_1.next(); !styleKeys_1_1.done; styleKeys_1_1 = styleKeys_1.next()) {
                    var k = styleKeys_1_1.value;
                    appliableStyles[k] = this.createStylesheet(__assign(__assign({}, computedStyles === null || computedStyles === void 0 ? void 0 : computedStyles[k]), override === null || override === void 0 ? void 0 : override[k]));
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (styleKeys_1_1 && !styleKeys_1_1.done && (_b = styleKeys_1.return)) _b.call(styleKeys_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            // const appliableStyles = Object.fromEntries(
            //   mapObject(computedStyles, ([k, v]) => [
            //     k,
            //     this.createStylesheet({ ...v, ...override?.[k] }),
            //   ]),
            // )
            return appliableStyles;
        }
        catch (e) {
            this.logger.error("Error on getStyles for Component ".concat(debugName, " \n").concat(variantList.join('\n'), "\n") + debugName, e, SCOPE);
            return {};
        }
    };
    VariantProvider.prototype.typeComponents = function (components) {
        var e_4, _a;
        var typed = {};
        try {
            for (var _b = __values(Object.entries(components)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), name_1 = _d[0], _e = __read(_d[1], 1), render = _e[0];
                typed[name_1] = render;
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return typed;
    };
    VariantProvider.prototype.setColorScheme = function (to) {
        var _this = this;
        this.theme.theme = to;
        // @ts-ignore
        this.theme.border = (0, helpers_1.createBorderHelpers)(this.theme, this.theme.IsBrowser, to);
        this.colorSchemeListeners.forEach(function (l) { return l(_this.theme); });
    };
    VariantProvider.prototype.onColorSchemeChange = function (callback) {
        var _this = this;
        var newLen = this.colorSchemeListeners.push(callback);
        return function () {
            _this.colorSchemeListeners.splice(newLen - 1);
        };
    };
    return VariantProvider;
}());
exports.VariantProvider = VariantProvider;
