"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withPrefixedComposition = void 0;
var TypeGuards = __importStar(require("./typeGuards"));
var object_1 = require("./object");
var lodash_1 = require("lodash");
function withPrefixedComposition(options) {
    var e_1, _a;
    var variants = TypeGuards.isString(options.variants) ? [options.variants] : options.variants;
    var styles = {};
    try {
        for (var variants_1 = __values(variants), variants_1_1 = variants_1.next(); !variants_1_1.done; variants_1_1 = variants_1.next()) {
            var variant = variants_1_1.value;
            var variantStyle = options.variantObject[variant](options.theme, '');
            var prefixedVariantStyle = Object.fromEntries(Object.entries(variantStyle).map(function (_a) {
                var _b = __read(_a, 2), k = _b[0], v = _b[1];
                return [
                    "".concat(options.prefix).concat((0, lodash_1.capitalize)(k)),
                    v
                ];
            }));
            styles = (0, object_1.deepMerge)(styles, prefixedVariantStyle);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (variants_1_1 && !variants_1_1.done && (_a = variants_1.return)) _a.call(variants_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return styles;
}
exports.withPrefixedComposition = withPrefixedComposition;
