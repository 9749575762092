"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionManager = void 0;
var Permission_1 = require("./Permission");
var PermissionManager = /** @class */ (function () {
    function PermissionManager(perms, options) {
        var e_1, _a;
        var _this = this;
        var _b;
        this.getMany = function (perms, options) { return __awaiter(_this, void 0, void 0, function () {
            var results, perms_1, perms_1_1, p, isArray, name_1, opts, _a, _b, e_2_1;
            var e_2, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        results = [];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, 7, 8]);
                        perms_1 = __values(perms), perms_1_1 = perms_1.next();
                        _d.label = 2;
                    case 2:
                        if (!!perms_1_1.done) return [3 /*break*/, 5];
                        p = perms_1_1.value;
                        isArray = Array.isArray(p);
                        name_1 = isArray ? p[0] : p;
                        opts = isArray ? p[1] : options;
                        _b = (_a = results).push;
                        return [4 /*yield*/, this.check(name_1, opts)];
                    case 3:
                        _b.apply(_a, [_d.sent()]);
                        _d.label = 4;
                    case 4:
                        perms_1_1 = perms_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (perms_1_1 && !perms_1_1.done && (_c = perms_1.return)) _c.call(perms_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, results];
                }
            });
        }); };
        // @ts-ignore
        this.get = function (name, options) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.check(name, options)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        this.params = perms;
        // @ts-ignore
        this._permissions = {};
        // @ts-ignore
        this.permissions = {};
        this.logger = options === null || options === void 0 ? void 0 : options.logger;
        this.permSubscribers = {};
        try {
            for (var _c = __values(Object.entries(this.params)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), permName = _e[0], actions = _e[1];
                var name_2 = permName;
                this._permissions[name_2] = new Permission_1.Permission(__assign(__assign({}, actions), { log: ((_b = this.logger) === null || _b === void 0 ? void 0 : _b.log) || (function () { return null; }) }), permName);
                this.permSubscribers[name_2] = [];
                // @ts-ignore
                this.permissions[name_2.toUpperCase()] = name_2;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.subscribers = [];
    }
    Object.defineProperty(PermissionManager.prototype, "values", {
        get: function () {
            return this._permissions;
        },
        enumerable: false,
        configurable: true
    });
    PermissionManager.prototype.check = function (name, options) {
        return __awaiter(this, void 0, void 0, function () {
            var previousStatus;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        previousStatus = this._permissions[name].status;
                        return [4 /*yield*/, this._permissions[name].check(options)];
                    case 1:
                        _a.sent();
                        if (this._permissions[name].status !== previousStatus) {
                            this.permSubscribers[name].forEach(function (sub) { return sub(_this._permissions[name]); });
                            this.subscribers.forEach(function (sub) { return sub(name, _this._permissions[name]); });
                        }
                        return [2 /*return*/, this._permissions[name]];
                }
            });
        });
    };
    PermissionManager.prototype.onPermissionChange = function (name, callback) {
        var _this = this;
        var subIdx = this.permSubscribers[name].push(callback) - 1;
        return function () {
            _this.permSubscribers[name].splice(subIdx, 1);
        };
    };
    PermissionManager.prototype.onChange = function (callback) {
        var _this = this;
        var subIdx = this.subscribers.push(callback) - 1;
        return function () {
            _this.subscribers.splice(subIdx, 1);
        };
    };
    PermissionManager.prototype.getState = function () {
        var e_3, _a;
        var state = {};
        try {
            for (var _b = __values(Object.entries(this._permissions)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = _c.value;
                var _e = _d, _f = __read(_e, 2), p = _f[0], _g = _f[1], _ig2 = _g.ask, _ig1 = _g.check, values = __rest(_g, ["ask", "check"]);
                state[p] = __assign({}, values);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return state;
    };
    PermissionManager.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, _a, _b, p, _c, _d, e_4_1;
            var e_4, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        res = {};
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 6, 7, 8]);
                        _a = __values(Object.keys(this._permissions)), _b = _a.next();
                        _f.label = 2;
                    case 2:
                        if (!!_b.done) return [3 /*break*/, 5];
                        p = _b.value;
                        _c = res;
                        _d = p;
                        return [4 /*yield*/, this.check(p, {
                                ask: false,
                            })];
                    case 3:
                        _c[_d] = _f.sent();
                        _f.label = 4;
                    case 4:
                        _b = _a.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_4_1 = _f.sent();
                        e_4 = { error: e_4_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (_b && !_b.done && (_e = _a.return)) _e.call(_a);
                        }
                        finally { if (e_4) throw e_4.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, res];
                }
            });
        });
    };
    return PermissionManager;
}());
exports.PermissionManager = PermissionManager;
