"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_STYLES = void 0;
var Button_1 = require("./Button");
var ContentView_1 = require("./ContentView");
var FileInput_1 = require("./FileInput");
var Image_1 = require("./Image");
var Modal_1 = require("./Modal");
var Overlay_1 = require("./Overlay");
var RadioInput_1 = require("./RadioInput");
var Slider_1 = require("./Slider");
var Text_1 = require("./Text");
var TextInput_1 = require("./TextInput");
var Touchable_1 = require("./Touchable");
var View_1 = require("./View");
var Drawer_1 = require("./Drawer");
var ActivityIndicator_1 = require("./ActivityIndicator");
var Select_1 = require("./Select");
var Tooltip_1 = require("./Tooltip");
var CenterWrapper_1 = require("./CenterWrapper");
var RouterPage_1 = require("./RouterPage");
var Avatar_1 = require("./Avatar");
var Switch_1 = require("./Switch");
var Icon_1 = require("./Icon");
var EmptyPlaceholder_1 = require("./EmptyPlaceholder");
exports.DEFAULT_STYLES = {
    Button: Button_1.ButtonStyles,
    View: View_1.ViewStyles,
    Checkbox: {},
    ContentView: ContentView_1.ContentViewStyles,
    FileInput: FileInput_1.FileInputStyles,
    Modal: Modal_1.ModalStyles,
    Overlay: Overlay_1.OverlayStyles,
    RadioInput: RadioInput_1.RadioInputStyles,
    Slider: Slider_1.SliderStyles,
    Text: Text_1.TextStyles,
    TextInput: TextInput_1.TextInputStyles,
    Touchable: Touchable_1.TouchableStyles,
    Icon: Icon_1.IconStyles,
    Image: Image_1.ImageStyles,
    Drawer: Drawer_1.DrawerStyles,
    FlatList: {},
    ActivityIndicator: ActivityIndicator_1.ActivityIndicatorStyles,
    Select: Select_1.SelectStyles,
    Tooltip: Tooltip_1.TooltipStyles,
    CenterWrapper: CenterWrapper_1.CenterWrapperStyles,
    Avatar: Avatar_1.AvatarStyles,
    PageRouter: RouterPage_1.RouterPageStyles,
    Switch: Switch_1.SwitchStyles,
    Navigation: {},
    EmptyPlaceholder: EmptyPlaceholder_1.EmptyPlaceholderStyles,
};
__exportStar(require("./Button"), exports);
__exportStar(require("./View"), exports);
__exportStar(require("./ContentView"), exports);
__exportStar(require("./FileInput"), exports);
__exportStar(require("./Modal"), exports);
__exportStar(require("./Overlay"), exports);
__exportStar(require("./RadioInput"), exports);
__exportStar(require("./Slider"), exports);
__exportStar(require("./Text"), exports);
__exportStar(require("./TextInput"), exports);
__exportStar(require("./Image"), exports);
__exportStar(require("./Touchable"), exports);
__exportStar(require("./ActivityIndicator"), exports);
__exportStar(require("./Drawer"), exports);
__exportStar(require("./Select"), exports);
__exportStar(require("./Tooltip"), exports);
__exportStar(require("./CenterWrapper"), exports);
__exportStar(require("./RouterPage"), exports);
__exportStar(require("./Avatar"), exports);
__exportStar(require("./Switch"), exports);
__exportStar(require("./Icon"), exports);
__exportStar(require("./EmptyPlaceholder"), exports);
