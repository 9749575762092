"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListState = void 0;
var react_1 = require("react");
function useListState(initialValue) {
    if (initialValue === void 0) { initialValue = []; }
    var _a = __read((0, react_1.useState)(initialValue), 2), state = _a[0], setState = _a[1];
    var append = function () {
        var items = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            items[_i] = arguments[_i];
        }
        return setState(function (current) { return __spreadArray(__spreadArray([], __read(current), false), __read(items), false); });
    };
    var prepend = function () {
        var items = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            items[_i] = arguments[_i];
        }
        return setState(function (current) { return __spreadArray(__spreadArray([], __read(items), false), __read(current), false); });
    };
    var insert = function (index) {
        var items = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            items[_i - 1] = arguments[_i];
        }
        return setState(function (current) { return __spreadArray(__spreadArray(__spreadArray([], __read(current.slice(0, index)), false), __read(items), false), __read(current.slice(index)), false); });
    };
    var apply = function (fn) { return setState(function (current) { return current.map(function (item, index) { return fn(item, index); }); }); };
    var remove = function () {
        var indices = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indices[_i] = arguments[_i];
        }
        return setState(function (current) { return current.filter(function (_, index) { return !indices.includes(index); }); });
    };
    var pop = function () { return setState(function (current) {
        var cloned = __spreadArray([], __read(current), false);
        cloned.pop();
        return cloned;
    }); };
    var shift = function () { return setState(function (current) {
        var cloned = __spreadArray([], __read(current), false);
        cloned.shift();
        return cloned;
    }); };
    var reorder = function (_a) {
        var from = _a.from, to = _a.to;
        return setState(function (current) {
            var cloned = __spreadArray([], __read(current), false);
            var item = current[from];
            cloned.splice(from, 1);
            cloned.splice(to, 0, item);
            return cloned;
        });
    };
    var setItem = function (index, item) { return setState(function (current) {
        var cloned = __spreadArray([], __read(current), false);
        cloned[index] = item;
        return cloned;
    }); };
    var setItemProp = function (index, prop, value) { return setState(function (current) {
        var _a;
        var cloned = __spreadArray([], __read(current), false);
        cloned[index] = __assign(__assign({}, cloned[index]), (_a = {}, _a[prop] = value, _a));
        return cloned;
    }); };
    var applyWhere = function (condition, fn) { return setState(function (current) { return current.map(function (item, index) { return (condition(item, index) ? fn(item, index) : item); }); }); };
    return [
        state,
        {
            setState: setState,
            append: append,
            prepend: prepend,
            insert: insert,
            pop: pop,
            shift: shift,
            apply: apply,
            applyWhere: applyWhere,
            remove: remove,
            reorder: reorder,
            setItem: setItem,
            setItemProp: setItemProp,
        },
    ];
}
exports.useListState = useListState;
