"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_AUTH_ERRORS = void 0;
exports.DEFAULT_AUTH_ERRORS = {
    'auth/wrong-password': 'Email or password is incorrect',
    'auth/not-registered': 'This user is not registered',
    'auth/requires-recent-login': 'You need to log in again to continue',
    'auth/invalid-login-credentials': 'Email or password is incorrect',
    'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed login attempts.',
    'auth/email-in-use': 'This email address is already taken',
    'auth/email-not-found': 'Could not find an account matching the specified email address',
    'auth/email-already-in-use': 'This email address is already taken',
    'auth/invalid-email': 'Email or password is incorrect',
    'auth/user-disabled': 'Could not find an account with the specified email address and password',
    'auth/user-not-found': 'Email or password is incorrect',
    'auth/missing-email': 'Could not find an account with the specified email address and password',
    'social': {
        '12501': null,
        'EUNSPECIFIED': null,
        'facebook-login-canceled': null,
        '-5': null,
        '1001': null,
    }
};
