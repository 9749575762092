"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSimilarLocale = exports.getNestedValues = exports.replaceVariables = exports.formatStrWithArgs = void 0;
function formatStrWithArgs(str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var i = 0;
    return str.replace(/%s/g, function () { return args[i++]; });
}
exports.formatStrWithArgs = formatStrWithArgs;
function replaceVariables(str, args) {
    if (!args)
        return str;
    var regex = /{{(.*?)}}/g;
    return str.replace(regex, function (_, match) {
        var variableName = match.trim();
        return args[variableName] || variableName;
    });
}
exports.replaceVariables = replaceVariables;
function getNestedValues(languageDictionary, key) {
    var properties = key.split('.');
    var nestedProp = properties.reduce(function (nestedObj, property) { return nestedObj && nestedObj[property]; }, languageDictionary);
    return typeof nestedProp === 'string' ? nestedProp : key;
}
exports.getNestedValues = getNestedValues;
function getSimilarLocale(locale, defaultLocale, languageDictionary) {
    var e_1, _a;
    var inputFirstPart = locale.substring(0, 2);
    var keys = Object.keys(languageDictionary);
    try {
        for (var keys_1 = __values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
            var key = keys_1_1.value;
            var keyFirstPart = key.substring(0, 2);
            if (inputFirstPart === keyFirstPart)
                return key;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return defaultLocale;
}
exports.getSimilarLocale = getSimilarLocale;
