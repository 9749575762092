"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentViewStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createContentViewStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createContentViewStyle(function () { return ({ wrapper: styles }); }); });
exports.ContentViewStyles = __assign(__assign({}, presets), { default: createContentViewStyle(function (theme) { return ({
        wrapper: __assign(__assign({ display: 'flex' }, theme.presets.column), theme.presets.alignCenter),
        loader: {
            alignSelf: 'center',
            height: 100,
            width: 100,
        },
    }); }) });
