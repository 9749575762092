import { Link, SocialButton, Text, View } from '@/components'
import { AnyFunction, useI18N } from '@codeleap/common'

type RedirectLinkProps = {
  route: AppRoute
  text: string
  textAction: string
}

export const RedirectLink = (props: RedirectLinkProps) => {
  const { route, text, textAction } = props
  return (
    <Link
      route={route}
      style={['noUnderline', 'color:neutral10', 'alignSelfCenter', 'marginTop:2']}
    >
      {text} <Text component='span' style={['textCenter', 'color:primary3']} text={textAction} />
    </Link>
  )
}

type RedirectModalProps = {
  text: string
  textAction: string
  onPress: AnyFunction
}

export const RedirectModal = (props: RedirectModalProps) => {
  const { onPress, text, textAction } = props
  return (
    <View style={['row', 'alignCenter', 'justifyCenter', 'marginTop:2', 'gap:1']}>
      <Text style={['textCenter']} text={text} />
      <Text
        text={textAction}
        onPress={onPress}
        style={['color:primary3', 'cursor:pointer']}
      />
    </View>
  )
}

export const SocialProviders = ({ onPress }) => {
  const { t } = useI18N()

  return (
    <View style={['column', 'gap:2']}>
      <Text
        text={t('Login.social')}
        style={['p3', 'alignSelfCenter']}
      />

      <View style={['row', 'gap:2']}>
        <SocialButton platform={'google'} handleSocial={onPress} />
        <SocialButton platform={'facebook'} handleSocial={onPress} />
        <SocialButton platform={'apple'} handleSocial={onPress} />
      </View>
    </View>
  )
}

export const AuthFormComponents = {
  RedirectLink,
  RedirectModal,
  SocialProviders,
}
