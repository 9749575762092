"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createTooltipStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createTooltipStyle(function () { return ({ wrapper: styles }); }); });
exports.TooltipStyles = __assign(__assign({}, presets), { default: createTooltipStyle(function (t) { return ({
        wrapper: {
            position: 'relative',
        },
        arrow: {
            content: '""',
            position: 'absolute',
            background: t.colors.backgroundSecondary,
            height: 10,
            width: 10,
            zIndex: -1,
        },
        bubble: __assign({ display: 'flex', background: t.colors.backgroundSecondary, zIndex: 10, position: 'absolute' }, t.spacing.padding(1)),
    }); }) });
