import { navigation, React } from '@/app'
import moment from 'moment'
import { Text, View, ActionIcon, CardBase } from '@/components'
import { APIClient } from '@/services'
import { AnyFunction, arePropsEqual, TypeGuards } from '@codeleap/common'

type PostCardProps = {
  post: APIClient.Post.Post
  remove?: (id: APIClient.Post.Post['id']) => void
  edit?: AnyFunction | null
  like?: AnyFunction
  favorite?: AnyFunction
}

const PostCardComponent = (props: PostCardProps) => {
  const {
    post,
    remove,
    edit,
    like,
    favorite,
  } = props

  const date = moment(post?.created_datetime).format('Do of MMM YY')
  const username = `${post?.profile?.first_name} ${post?.profile?.last_name}`

  return (
    <CardBase style={['card:post']} onPress={() => navigation.navigate('Crud.View', { id: post?.id })}>
      <View style={['row', 'justifySpaceBetween', 'fullWidth']}>
        {TypeGuards.isFunction(remove) ? (
          <ActionIcon
            onPress={remove}
            icon='x'
            style={['minimal', 'normalize']}
            debugName='post:remove'
            propagate={false}
          />
        ) : null}

        {TypeGuards.isFunction(edit) ? (
          <ActionIcon
            onPress={edit}
            icon='edit'
            style={['minimal', 'normalize']}
            debugName='post:edit'
            propagate={false}
          />
        ) : null}
      </View>

      <View style={['row', 'justifySpaceBetween', 'fullWidth']}>
        <Text text={username} style={['h5']} />
        <Text text={`${date}`} style={['p2']} />
      </View>

      <Text text={post?.title} style={['h4', 'fullWidth']} />

      <Text text={post?.content} style={['p1']} />

      <View style={['row', 'justifySpaceBetween', 'fullWidth']}>
        <ActionIcon
          onPress={like}
          icon={post?.is_liked ? 'heart-filled' : 'heart'}
          style={['minimal', 'row', 'normalize']}
          debugName='post:like'
          propagate={false}
          disabled={!TypeGuards.isFunction(like)}
        >
          <Text text={String(post?.likes)} style={['p2', 'marginLeft:1']} />
        </ActionIcon>

        <ActionIcon
          onPress={favorite}
          icon={post?.is_favorited ? 'bookmark-filled' : 'bookmark'}
          style={['minimal', 'normalize']}
          debugName='post:favorite'
          propagate={false}
          disabled={!TypeGuards.isFunction(like)}
        />
      </View>
    </CardBase>
  )
}

export const PostCard = React.memo(PostCardComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['post'],
  })
})
