"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CenterWrapperStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createCenterWrapperStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createCenterWrapperStyle(function () { return ({ innerWrapper: styles }); }); });
exports.CenterWrapperStyles = __assign(__assign({}, presets), { default: createCenterWrapperStyle(function (Theme) {
        var _a;
        return ({
            wrapper: {
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
            },
            innerWrapper: (_a = {
                    flex: 1,
                    display: 'flex',
                    width: '100%',
                    maxWidth: 1280
                },
                _a[Theme.media.down('xxlarge')] = {
                    paddingLeft: Theme.spacing.value(16),
                    paddingRight: Theme.spacing.value(16),
                },
                _a[Theme.media.down('large')] = {
                    paddingLeft: Theme.spacing.value(12),
                    paddingRight: Theme.spacing.value(12),
                },
                _a[Theme.media.down('largeish')] = {
                    paddingLeft: Theme.spacing.value(8),
                    paddingRight: Theme.spacing.value(8),
                },
                _a[Theme.media.down('mid')] = {
                    paddingLeft: Theme.spacing.value(4),
                    paddingRight: Theme.spacing.value(4),
                },
                _a[Theme.media.down('small')] = {
                    paddingLeft: Theme.spacing.value(2),
                    paddingRight: Theme.spacing.value(2),
                },
                _a),
        });
    }) });
