"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlackService = void 0;
var util_1 = require("util");
var utils_1 = require("../../utils");
var DEFAULT_CHANNEL = '#_dev_logs';
var DEFAULT_BASE_URL = 'https://slack.com/api/chat.postMessage';
var SlackService = /** @class */ (function () {
    function SlackService(settings) {
        var _a, _b;
        this.serializers = {
            version: function (IsDev) {
                return IsDev ? 'debug' : 'release';
            },
        };
        this.echoConfig = (_a = settings === null || settings === void 0 ? void 0 : settings.Slack) === null || _a === void 0 ? void 0 : _a.echo;
        this.isDev = (_b = settings === null || settings === void 0 ? void 0 : settings.Environment) === null || _b === void 0 ? void 0 : _b.IsDev;
        this.appName = settings === null || settings === void 0 ? void 0 : settings.AppName;
    }
    SlackService.prototype.echo = function (label, slackData, moduleName, messageOptions) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (moduleName === void 0) { moduleName = null; }
        if (messageOptions === void 0) { messageOptions = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var options, slack, enabled, settingsData, data, err_1;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        options = this.parseOptions(messageOptions);
                        slack = this.parseData(label, slackData, options.info, moduleName);
                        enabled = utils_1.TypeGuards.isBoolean(this.echoConfig.enabled) ? this.echoConfig.enabled : true;
                        if (!options.send || !this.api || !this.echoConfig || !enabled)
                            return [2 /*return*/];
                        settingsData = (_b = (_a = this === null || this === void 0 ? void 0 : this.echoConfig) === null || _a === void 0 ? void 0 : _a.options) !== null && _b !== void 0 ? _b : {};
                        _j.label = 1;
                    case 1:
                        _j.trys.push([1, 3, , 4]);
                        data = __assign({ 'channel': (_d = (_c = this === null || this === void 0 ? void 0 : this.echoConfig) === null || _c === void 0 ? void 0 : _c.channel) !== null && _d !== void 0 ? _d : DEFAULT_CHANNEL, text: slack, 'username': "".concat(this.appName, " Log"), 'icon_url': (_e = this === null || this === void 0 ? void 0 : this.echoConfig) === null || _e === void 0 ? void 0 : _e.icon }, settingsData);
                        return [4 /*yield*/, this.api.post('', data, {
                                baseURL: (_g = (_f = this === null || this === void 0 ? void 0 : this.echoConfig) === null || _f === void 0 ? void 0 : _f.baseURL) !== null && _g !== void 0 ? _g : DEFAULT_BASE_URL,
                                headers: {
                                    Authorization: "Bearer ".concat((_h = this === null || this === void 0 ? void 0 : this.echoConfig) === null || _h === void 0 ? void 0 : _h.token),
                                },
                            })];
                    case 2:
                        _j.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _j.sent();
                        console.error('Failed to echo', err_1, 'logger echoSlack');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SlackService.prototype.parseOptions = function (options) {
        var _this = this;
        var _a = options.sendIn, sendIn = _a === void 0 ? [] : _a, _b = options.include, include = _b === void 0 ? [] : _b;
        var hasSendIn = sendIn.length >= 1;
        var isDebug = hasSendIn ? sendIn.includes('debug') : true;
        var isRelease = hasSendIn ? sendIn.includes('release') : true;
        if (!isDebug && this.isDev || !isRelease && !this.isDev) {
            return {
                info: '',
                send: false,
            };
        }
        var str = '';
        var separator = ' - ';
        include.forEach(function (k) {
            var _a, _b;
            var data = (_b = (_a = _this.serializers)[k]) === null || _b === void 0 ? void 0 : _b.call(_a, _this.isDev);
            str = "".concat(str).concat(str.length > 0 ? separator : '', "[").concat(data, "]");
        });
        return {
            info: str,
            send: true,
        };
    };
    SlackService.prototype.parseData = function (label, data, info, module) {
        var obj = !info ? data : __assign(__assign({}, data), { info: info });
        var args = ["".concat(!module ? '' : "(".concat(module, ") ")).concat(label, ": "), obj];
        var slack = args.map(function (i) {
            if (typeof i === 'object') {
                try {
                    return (0, util_1.inspect)(i, {
                        depth: 5,
                        compact: false,
                        showHidden: true,
                    });
                }
                catch (e) {
                    return "".concat(i, " (Unserializable value)");
                }
            }
            return String(i);
        }).join(' ');
        return slack;
    };
    return SlackService;
}());
exports.SlackService = SlackService;
