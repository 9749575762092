"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Segment = void 0;
var defaultConfig = {
    getUserTraits: function (user) { return user; },
    mode: 'prod',
    prefix: {
        'dev': 'dev',
        'prod': null,
    }
};
var Segment = /** @class */ (function () {
    function Segment(config) {
        this.config = defaultConfig;
        this._enabled = true;
        this.apiKey = null;
        this.client = null;
        this.identifiedUser = false;
        var apiKey = config.apiKey, _a = config.enabled, enabled = _a === void 0 ? true : _a, createClient = config.createClient, generalConfig = __rest(config, ["apiKey", "enabled", "createClient"]);
        this.config = __assign(__assign({}, this.config), generalConfig);
        this.apiKey = apiKey;
        this._enabled = enabled;
        if (enabled) {
            this.client = createClient(this.apiKey);
        }
    }
    Object.defineProperty(Segment.prototype, "enabled", {
        get: function () {
            return this.client !== null && this._enabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Segment.prototype, "eventPrefix", {
        get: function () {
            var _a, _b;
            var modePrefix = (_b = (_a = this.config.prefix) === null || _a === void 0 ? void 0 : _a[this.config.mode]) !== null && _b !== void 0 ? _b : null;
            return !modePrefix ? '' : "".concat(modePrefix, "_");
        },
        enumerable: false,
        configurable: true
    });
    Segment.prototype.track = function (eventName, properties) {
        var _a, _b;
        if (properties === void 0) { properties = undefined; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.enabled)
                            return [2 /*return*/];
                        return [4 /*yield*/, ((_b = (_a = this.client) === null || _a === void 0 ? void 0 : _a.track) === null || _b === void 0 ? void 0 : _b.call(_a, "".concat(this.eventPrefix).concat(eventName), properties))];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Segment.prototype.screen = function (name, options) {
        var _a, _b;
        if (options === void 0) { options = undefined; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.enabled)
                            return [2 /*return*/];
                        return [4 /*yield*/, ((_b = (_a = this.client) === null || _a === void 0 ? void 0 : _a.screen) === null || _b === void 0 ? void 0 : _b.call(_a, "".concat(this.eventPrefix).concat(name), options))];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Segment.prototype.group = function (groupId, groupTraits) {
        var _a;
        if (groupTraits === void 0) { groupTraits = undefined; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.enabled)
                            return [2 /*return*/];
                        return [4 /*yield*/, ((_a = this.client) === null || _a === void 0 ? void 0 : _a.group("".concat(this.eventPrefix).concat(groupId), groupTraits))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Segment.prototype.alias = function (newUserId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.enabled)
                            return [2 /*return*/];
                        return [4 /*yield*/, ((_a = this.client) === null || _a === void 0 ? void 0 : _a.alias(newUserId))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Segment.prototype.reset = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.enabled)
                            return [2 /*return*/];
                        return [4 /*yield*/, ((_a = this.client) === null || _a === void 0 ? void 0 : _a.reset())];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Segment.prototype.identifyUser = function (user) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var userTraits;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.enabled || this.identifiedUser)
                            return [2 /*return*/];
                        userTraits = this.config.getUserTraits(user);
                        return [4 /*yield*/, ((_b = (_a = this.client) === null || _a === void 0 ? void 0 : _a.identify) === null || _b === void 0 ? void 0 : _b.call(_a, user === null || user === void 0 ? void 0 : user.id, userTraits))];
                    case 1:
                        _c.sent();
                        this.identifiedUser = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    Segment.prototype.setMode = function (newMode) {
        this.config = __assign(__assign({}, this.config), { mode: newMode });
    };
    return Segment;
}());
exports.Segment = Segment;
