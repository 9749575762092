"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouterPageStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createRouterPageStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createRouterPageStyle(function () { return ({ topMenu: styles, sideMenu: styles }); }); });
exports.RouterPageStyles = __assign(__assign({}, presets), { default: createRouterPageStyle(function (Theme) {
        var _a;
        return ({
            menuItem: {
                alignItems: 'center',
                padding: Theme.spacing.value(2.5),
                flexDirection: 'column',
                borderRadius: Theme.borderRadius.medium,
                borderWidth: 1,
                borderStyle: 'solid',
                // ...Theme.debug('red'),
                borderColor: 'transparent',
                width: Theme.spacing.value(5),
                height: Theme.spacing.value(5),
                margin: 2,
                '&:hover': {
                    background: Theme.colors.gray,
                },
            },
            'menuItem:mobile': __assign(__assign({ display: 'flex' }, Theme.presets.center), { flexDirection: 'column-reverse', justifyContent: 'space-between' }),
            'menuItem:mobile:text': {
                color: Theme.colors.black,
                padding: Theme.spacing.value(1),
                textTransform: 'uppercase',
            },
            'menuItem:text': {
                fontWeight: '500',
                color: Theme.colors.primary,
                textTransform: 'uppercase',
                textAlign: 'center',
            },
            'menuItem:text:selected': {
                fontWeight: 'bold',
                color: Theme.colors.gray,
            },
            sideMenu: (_a = {
                    borderRadius: Theme.borderRadius.medium,
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.16)',
                    marginRight: Theme.spacing.value(6),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    width: Theme.spacing.value(11),
                    flexShrink: 0,
                    flexGrow: 0
                },
                _a[Theme.media.down('xlarge')] = {
                    marginRight: Theme.spacing.value(4),
                },
                _a[Theme.media.down('large')] = {
                    marginRight: Theme.spacing.value(3),
                    width: 90,
                },
                _a),
            topMenu: {
                width: '100%',
                backgroundColor: Theme.colors.white,
                height: 80,
            },
            horizontalScroll: {
                display: 'flex',
                justifyContent: 'space-between',
            },
        });
    }) });
