"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBorderHelpers = void 0;
var utils_1 = require("../utils");
function createBorderHelpers(values, browser, theme) {
    var e_1, _a;
    var helpers = {
        create: function (_a) {
            var e_2, _b;
            var width = _a.width, color = _a.color, _c = _a.style, style = _c === void 0 ? 'solid' : _c, _d = _a.directions, directions = _d === void 0 ? ['left', 'top', 'bottom', 'right'] : _d;
            var borderStyles = {};
            try {
                for (var directions_1 = __values(directions), directions_1_1 = directions_1.next(); !directions_1_1.done; directions_1_1 = directions_1.next()) {
                    var direction = directions_1_1.value;
                    var property = "border".concat((0, utils_1.capitalize)(direction));
                    borderStyles["".concat(property, "Color")] = color;
                    borderStyles["".concat(property, "Width")] = width;
                    if (browser) {
                        borderStyles["".concat(property, "Style")] = style;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (directions_1_1 && !directions_1_1.done && (_b = directions_1.return)) _b.call(directions_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return borderStyles;
        },
    };
    var allColors = Object.entries(values.colors[theme]);
    var _loop_1 = function (name_1, color) {
        helpers[name_1] = function (args) {
            if (typeof args === 'number') {
                return helpers.create({
                    width: args,
                    color: color,
                });
            }
            else {
                var width = args.width, _a = args.style, style = _a === void 0 ? 'solid' : _a, _b = args.directions, directions = _b === void 0 ? ['left', 'top', 'bottom', 'right'] : _b;
                return helpers.create({
                    width: width,
                    style: style,
                    directions: directions,
                    color: color,
                });
            }
        };
    };
    try {
        for (var allColors_1 = __values(allColors), allColors_1_1 = allColors_1.next(); !allColors_1_1.done; allColors_1_1 = allColors_1.next()) {
            var _b = __read(allColors_1_1.value, 2), name_1 = _b[0], color = _b[1];
            _loop_1(name_1, color);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (allColors_1_1 && !allColors_1_1.done && (_a = allColors_1.return)) _a.call(allColors_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return helpers;
}
exports.createBorderHelpers = createBorderHelpers;
