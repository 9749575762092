import { AppForms } from '@/app'
import { AnyFunction, TypeGuards, useForm, useI18N } from '@codeleap/common'
import { AuthFormComponents, Button, Text, TextInput, View } from '@/components'
import { APIClient } from '@/services'
import { useKeydown } from '@codeleap/web'

type ForgotPasswordBeforeFormProps = {
  onRequest?: AnyFunction
}

export const ForgotPasswordBeforeForm = ({ onRequest }: ForgotPasswordBeforeFormProps) => {
  const form = useForm(AppForms.forgotPassword, {})
  const { t } = useI18N()

  const { requestPasswordReset } = APIClient.Session.useChangePassword()

  const onRequestReset = async () => {
    await requestPasswordReset(form.values.email)
    if (TypeGuards.isFunction(onRequest)) onRequest()
  }

  useKeydown('Enter', () => {
    const isInvalidSubmit = !form.isValid
    if (isInvalidSubmit) return
    onRequestReset?.()
  }, [form?.values])

  return (
    <View style={['flex', 'column']} >
      <Text
        style={['h1', 'extraBold', 'marginBottom:1']}
        text={t('forgot.title')}
      />

      <TextInput
        {...form.register('email')}
        leftIcon={{ name: 'mail' }}
      />

      <View style={['column', 'marginTop:2']}>
        <Button
          text={t('actions.continue')}
          debugName='Request password reset'
          disabled={!form.isValid}
          onPress={onRequestReset}
          style={['large', 'fullWidth', !form.isValid && 'outline']}
        />

        <AuthFormComponents.RedirectLink
          route={'Auth.Login'}
          text={'Go back to'}
          textAction={'Login'}
        />
      </View>
    </View>
  )
}
