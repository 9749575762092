import { create } from 'zustand'
import { AppImages, LocalStorage } from '@/app'
import { AnyFunction, TypeGuards } from '@codeleap/common'

type OnboardingStore = {
  page: number
  finished: 'true' | 'false' | 'null'
  visible: boolean
  next: AnyFunction
  close: AnyFunction
  previous: AnyFunction
  check: AnyFunction
  show: AnyFunction
}

export const OnboardingStore = create<OnboardingStore>((set) => ({
  page: 0,
  visible: false,
  finished: 'null',
  close: () => set(() => {
    LocalStorage.removeItem('ONBOARDING_FINISHED')
    return { visible: false, finished: 'true' }
  }),
  previous: () => set((state) => {
    const currentPage = state.page

    const isFirst = currentPage <= 0

    if (!isFirst) {
      return { page: currentPage - 1 }
    }
  }),
  next: () => set((state) => {
    const currentPage = state.page

    const isLast = currentPage >= onboardingPages?.length - 1

    if (isLast) {
      LocalStorage.removeItem('ONBOARDING_FINISHED')
      return { visible: false, finished: 'true' }
    } else {
      return { page: currentPage + 1 }
    }
  }),
  show: () => set(() => {
    LocalStorage.replaceItem('ONBOARDING_FINISHED', false)
    return { visible: true, finished: 'false' }
  }),
  check: () => set(() => {
    const finishedStorage = LocalStorage.getItem('ONBOARDING_FINISHED', false)
    const finished: any = TypeGuards.isString(finishedStorage) ? finishedStorage : 'null'

    return { visible: finished === 'false', finished }
  }),
}))

export const onboardingPages = [
  {
    title: 'A beautiful headline talking about the app',
    text: 'Lorem ipsum dolor sit amet consectetur. Fermentum adipiscing ultricies pellentesque pretium vitae velit eget adipiscing non.',
    image: AppImages.Onboarding1,
  },
  {
    title: 'A beautiful headline talking about the app',
    text: 'Lorem ipsum dolor sit amet consectetur. Fermentum adipiscing ultricies pellentesque pretium vitae velit eget adipiscing non.',
    image: AppImages.Onboarding2,
  },
]
