"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppAuthErrors = void 0;
var data_1 = require("./data");
__exportStar(require("./types"), exports);
var AuthError = /** @class */ (function (_super) {
    __extends(AuthError, _super);
    function AuthError(message, code) {
        var _this = this;
        var _error = 'AuthError:' + (code === null || code === void 0 ? void 0 : code.toLocaleUpperCase());
        _this = _super.call(this, _error) || this;
        _this.name = _error;
        _this.code = code;
        _this.msg = message;
        return _this;
    }
    return AuthError;
}(Error));
var AppAuthErrors = /** @class */ (function () {
    function AppAuthErrors(newErrors, errorFunction) {
        var _a, _b;
        this.defaultErrors = data_1.DEFAULT_AUTH_ERRORS;
        this.socialKey = 'social';
        var defaultErrors = data_1.DEFAULT_AUTH_ERRORS;
        var socialAuthErrors = __assign(__assign({}, defaultErrors[this.socialKey]), ((_a = newErrors === null || newErrors === void 0 ? void 0 : newErrors[this.socialKey]) !== null && _a !== void 0 ? _a : {}));
        var socialErrors = this.registryErrors(socialAuthErrors);
        this.social = socialErrors;
        if (!!newErrors['social']) {
            delete newErrors['social'];
        }
        if (!!defaultErrors['social']) {
            delete defaultErrors['social'];
        }
        var baseAuthErrors = __assign(__assign({}, defaultErrors), ((_b = newErrors) !== null && _b !== void 0 ? _b : {}));
        var authErrors = this.registryErrors(baseAuthErrors);
        this.errors = authErrors;
        this.registryErrorFunction(errorFunction);
    }
    AppAuthErrors.prototype.getError = function (err) {
        var _a, _b, _c, _d, _e, _f;
        if (!err)
            return null;
        if (typeof err == 'string') {
            return (_b = (_a = this.errors) === null || _a === void 0 ? void 0 : _a[err]) !== null && _b !== void 0 ? _b : (_c = this.social) === null || _c === void 0 ? void 0 : _c[err];
        }
        var authError = err;
        if (!!(authError === null || authError === void 0 ? void 0 : authError.code)) {
            return (_e = (_d = this.errors) === null || _d === void 0 ? void 0 : _d[authError === null || authError === void 0 ? void 0 : authError.code]) !== null && _e !== void 0 ? _e : (_f = this.social) === null || _f === void 0 ? void 0 : _f[authError === null || authError === void 0 ? void 0 : authError.code];
        }
        return null;
    };
    AppAuthErrors.prototype.isError = function (err, key) {
        var _a;
        var _error = (_a = this.errors) === null || _a === void 0 ? void 0 : _a[key];
        return err == _error.code || (err === null || err === void 0 ? void 0 : err.code) == _error.code;
    };
    AppAuthErrors.prototype.verifyError = function (err) {
        return !!(this.getError(err));
    };
    AppAuthErrors.prototype.exception = function (err) {
        var _error = this.getError(err);
        if (_error != null) {
            throw new AuthError(_error === null || _error === void 0 ? void 0 : _error.msg, _error === null || _error === void 0 ? void 0 : _error.code);
        }
    };
    AppAuthErrors.prototype.createAuthError = function (message, code) {
        return new AuthError(message, code);
    };
    AppAuthErrors.prototype.registryErrors = function (unregisteredErrors) {
        var state = {};
        var _loop_1 = function (errorKey) {
            var errorMsg = unregisteredErrors[errorKey];
            if (typeof errorMsg === 'string' || errorMsg == null) {
                state[errorKey] = new AuthError(function () { return errorMsg; }, errorKey);
            }
            else if (typeof errorMsg === 'function') {
                state[errorKey] = new AuthError(errorMsg, errorKey);
            }
        };
        for (var errorKey in unregisteredErrors) {
            _loop_1(errorKey);
        }
        return state;
    };
    AppAuthErrors.prototype.registryErrorFunction = function (pureErrorFunction) {
        var _this = this;
        this.onError = function (err, module, args) {
            if (module === void 0) { module = null; }
            if (args === void 0) { args = {}; }
            var authError = _this.getError(err);
            pureErrorFunction === null || pureErrorFunction === void 0 ? void 0 : pureErrorFunction(authError, module, args);
        };
    };
    return AppAuthErrors;
}());
exports.AppAuthErrors = AppAuthErrors;
