"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = void 0;
// import { queryClient } from '@/services/api'
var react_1 = require("react");
var react_query_1 = require("@tanstack/react-query");
var utils_1 = require("./utils");
var utils_2 = require("../../utils");
function usePagination(key, params) {
    var _this = this;
    function withOverride(op, values) {
        var _a;
        var overrideFn = (_a = params === null || params === void 0 ? void 0 : params.overrides) === null || _a === void 0 ? void 0 : _a[op];
        var a = values;
        if (overrideFn) {
            // @ts-ignore
            return overrideFn(a);
        }
        // @ts-ignore
        return values;
    }
    var QUERY_KEYS = (0, react_1.useMemo)(function () {
        if (utils_2.TypeGuards.isString(key)) {
            return (0, utils_1.getQueryKeys)(key);
        }
        return {
            create: key.create.key,
            list: key.list.key,
            remove: key.remove.key,
            update: key.update.key,
            retrieve: key.retrieve.key(1).slice(0, 2),
        };
    }, [key]);
    var _a = __read((0, react_1.useState)(false), 2), isRefreshing = _a[0], setRefreshing = _a[1];
    var queryClient = (0, react_query_1.useQueryClient)();
    var listFn = function (_a) {
        var pageParam = _a.pageParam;
        return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, params.onList(pageParam)];
                    case 1:
                        data = _b.sent();
                        return [2 /*return*/, data];
                }
            });
        });
    };
    var defaultListParams = {
        initialData: {
            pageParams: [
                {
                    limit: params.limit,
                    offset: 0,
                },
            ],
            pages: [],
        },
        refetchOnMount: function (query) {
            return query.state.dataUpdateCount === 0 || query.isStaleByTime();
        },
        getNextPageParam: function (page, pages) {
            var currentTotal = pages.reduce(function (acc, p) { return p.results.length + acc; }, 0);
            if (currentTotal >= ((page === null || page === void 0 ? void 0 : page.count) || Infinity)) {
                return undefined;
            }
            return {
                limit: params.limit,
                offset: currentTotal,
            };
        },
        queryFn: listFn,
        queryKey: QUERY_KEYS.list,
        keepPreviousData: true,
    };
    var list = (0, react_query_1.useInfiniteQuery)(withOverride('list', defaultListParams));
    var _b = __read((0, utils_2.useCounter)(), 2), retriveItemCounter = _b[0], setRetrieveCount = _b[1];
    var addItemsToMap = (0, react_1.useRef)({});
    var _c = (0, react_1.useMemo)(function () {
        var flatData = (0, utils_1.getPaginationData)({
            keyExtractor: params.keyExtractor,
            queryKeyOrList: list.data,
            filter: params === null || params === void 0 ? void 0 : params.filter,
            derive: params === null || params === void 0 ? void 0 : params.deriveData,
        });
        return flatData;
    }, [list.dataUpdatedAt]), flatItems = _c.flatItems, derivedData = _c.derivedData, pagesById = _c.pagesById, itemMap = _c.itemMap;
    var retrieveParams = ((params === null || params === void 0 ? void 0 : params.where) || []).filter(function (x) { return typeof x !== 'undefined'; });
    var defaultRetrieveParams = {
        queryKey: __spreadArray(__spreadArray([], __read(QUERY_KEYS.retrieve), false), __read(retrieveParams), false),
        queryFn: function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!retrieveParams.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, params.onRetrieve.apply(params, __spreadArray([], __read(retrieveParams), false))];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/, null];
                }
            });
        }); },
        onSuccess: function (data) {
            if (!data)
                return;
            // queryClient.setQueryData<ListQueryData>(QUERY_KEYS.list, old => {
            //   const itemId = params.keyExtractor(data)
            //   if (!itemMap[itemId]) {
            //     old.pages[0].results.unshift(data)
            //     // @ts-ignore
            //     old.pageParams[0].limit += 1
            //   } else {
            //     const [itemPage, itemIdx] = pagesById[itemId]
            //     old.pages[itemPage].results[itemIdx] = data
            //   }
            //   return old
            // })
            var itemId = params.keyExtractor(data);
            addItemsToMap.current[itemId] = data;
            setRetrieveCount();
        },
    };
    var retrieve = (0, react_query_1.useQuery)(withOverride('retrieve', defaultRetrieveParams));
    var append = function (args) {
        return queryClient.setQueryData(QUERY_KEYS.list, function (old) {
            var _a, _b;
            var itemsToAppend = utils_2.TypeGuards.isArray(args.item) ? args.item : [args.item];
            if (args.to == 'end') {
                var idx = old.pages.length - 1;
                (_a = old.pages[idx].results).push.apply(_a, __spreadArray([], __read(itemsToAppend), false));
                // @ts-ignore
                old.pageParams[idx].limit += itemsToAppend.length;
            }
            else {
                (_b = old.pages[0].results).unshift.apply(_b, __spreadArray([], __read(itemsToAppend), false));
                // @ts-ignore
                old.pageParams[0].limit += itemsToAppend.length;
            }
            return old;
        });
    };
    var defaultCreateParams = {
        mutationKey: QUERY_KEYS.create,
        mutationFn: params.onCreate,
        onMutate: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.beforeMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'create'))];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        onError: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.afterMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'create', {
                                status: 'error',
                            }))];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        onSuccess: function (newItem) {
            var _a;
            (_a = params === null || params === void 0 ? void 0 : params.afterMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'create', {
                status: 'success',
                item: newItem,
            });
            append({
                item: newItem,
                to: params === null || params === void 0 ? void 0 : params.appendTo,
            });
        },
    };
    var create = (0, react_query_1.useMutation)(withOverride('create', defaultCreateParams));
    var updateItem = function (data) {
        var itemId = params.keyExtractor(data);
        queryClient.setQueryData(QUERY_KEYS.list, function (old) {
            var _a = __read(pagesById[itemId], 2), pageIdx = _a[0], itemIdx = _a[1];
            old.pages[pageIdx].results[itemIdx] = data;
            return old;
        });
    };
    var defaultUpdateParams = {
        mutationKey: QUERY_KEYS.update,
        mutationFn: params.onUpdate,
        onMutate: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.beforeMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'update'))];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        onError: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.afterMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'update', {
                                status: 'error',
                            }))];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        onSuccess: function (data) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.afterMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'update', {
                                status: 'success',
                                item: data,
                            }))];
                        case 1:
                            _b.sent();
                            updateItem(data);
                            return [2 /*return*/];
                    }
                });
            });
        },
    };
    var update = (0, react_query_1.useMutation)(withOverride('update', defaultUpdateParams));
    var removeItem = function (id) {
        return queryClient.setQueryData(QUERY_KEYS.list, function (oldData) {
            var _a;
            var _b = __read(pagesById[id], 2), pageIdx = _b[0], itemIdx = _b[1];
            oldData.pages[pageIdx].results.splice(itemIdx, 1);
            var updateIdx = pageIdx + 1;
            while (updateIdx < oldData.pageParams.length) {
                if (utils_2.TypeGuards.isUndefined((_a = oldData === null || oldData === void 0 ? void 0 : oldData.pageParams) === null || _a === void 0 ? void 0 : _a[updateIdx])) {
                    break;
                }
                // @ts-ignore
                oldData.pageParams[updateIdx].offset -= 1;
                updateIdx += 1;
            }
            return oldData;
        });
    };
    var defaultRemoveParams = {
        mutationKey: QUERY_KEYS.remove,
        mutationFn: params.onRemove,
        onMutate: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.beforeMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'remove'))];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        onError: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.afterMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'remove', {
                                status: 'error',
                            }))];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        onSuccess: function (data) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var _id;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _id = params.keyExtractor(data);
                            return [4 /*yield*/, ((_a = params === null || params === void 0 ? void 0 : params.afterMutate) === null || _a === void 0 ? void 0 : _a.call(params, 'remove', {
                                    status: 'success',
                                    item: data,
                                }))];
                        case 1:
                            _b.sent();
                            removeItem(_id);
                            return [2 /*return*/];
                    }
                });
            });
        },
    };
    var remove = (0, react_query_1.useMutation)(withOverride('remove', defaultRemoveParams));
    var listQuery = __assign(__assign({}, list), { isRefreshing: isRefreshing, refresh: function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            setRefreshing(true);
            list.refetch.apply(list, __spreadArray([], __read(params), false)).then(function () {
                setRefreshing(false);
            });
        } });
    var itemName = (params === null || params === void 0 ? void 0 : params.itemName) || key;
    return {
        items: flatItems,
        pagesById: pagesById,
        itemMap: __assign(__assign({}, addItemsToMap.current), itemMap),
        itemName: itemName,
        queries: {
            list: listQuery,
            update: update,
            remove: remove,
            create: create,
            retrieve: retrieve,
        },
        retrievedItem: retrieve.data,
        create: create.mutateAsync,
        remove: remove.mutateAsync,
        update: update.mutateAsync,
        derivedData: derivedData,
        QUERY_KEYS: QUERY_KEYS,
        params: params,
        objects: {
            remove: removeItem,
            append: append,
            update: updateItem,
        },
        options: params,
    };
}
exports.usePagination = usePagination;
