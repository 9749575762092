"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIError = exports.makeFetcher = exports.RequestClient = void 0;
var RequestClient_1 = require("./RequestClient");
var axios_1 = require("axios");
__exportStar(require("axios"), exports);
__exportStar(require("./types"), exports);
var RequestClient_2 = require("./RequestClient");
Object.defineProperty(exports, "RequestClient", { enumerable: true, get: function () { return RequestClient_2.RequestClient; } });
function makeFetcher(settings, override) {
    return new RequestClient_1.RequestClient(__assign({ baseURL: settings.Environment.IsDev ? settings.Fetch.DevelopmentURL : settings.Fetch.ProductionURL, automaticMultipartParsing: true, headers: new axios_1.AxiosHeaders() }, override));
}
exports.makeFetcher = makeFetcher;
var APIError_1 = require("./APIError");
Object.defineProperty(exports, "APIError", { enumerable: true, get: function () { return APIError_1.APIError; } });
