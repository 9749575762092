"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.range = exports.flatten = exports.uniqueArrayByProperty = exports.objectFromArray = void 0;
function objectFromArray(arr, keyAccessor) {
    var getObjectKey = function (_, idx) { return idx; };
    if (keyAccessor) {
        switch (typeof keyAccessor) {
            case 'string':
                getObjectKey = function (value) { return value[keyAccessor]; };
                break;
            case 'function':
                getObjectKey = keyAccessor;
                break;
        }
    }
    var indexedMap = arr.map(function (value, idx) { return [getObjectKey(value, idx), value]; });
    return Object.fromEntries(indexedMap);
}
exports.objectFromArray = objectFromArray;
function uniqueArrayByProperty(array, getProperty) {
    return Object.values(objectFromArray(array, getProperty));
}
exports.uniqueArrayByProperty = uniqueArrayByProperty;
function flatten(arr) {
    var newArr = [];
    arr.forEach(function (item) {
        if (Array.isArray(item)) {
            newArr = __spreadArray(__spreadArray([], __read(newArr), false), __read(flatten(item)), false);
        }
        else {
            newArr.push(item);
        }
    });
    return newArr;
}
exports.flatten = flatten;
function range(start, end) {
    var length = end - start + 1;
    return Array.from({ length: length }, function (_, index) { return index + start; });
}
exports.range = range;
