"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Analytics = void 0;
var obfuscate_1 = require("../../utils/obfuscate");
var Analytics = /** @class */ (function () {
    function Analytics(callers, settings) {
        this.callers = callers;
        this.settings = settings;
        this.callers.init();
    }
    Analytics.prototype.prepare = function () {
        var data = this.callers.prepareData();
        return data;
    };
    Analytics.prototype.obfuscate = function (data) {
        var _a, _b, _c, _d, _e, _f;
        return (0, obfuscate_1.obfuscate)({
            object: data,
            keys: ((_c = (_b = (_a = this === null || this === void 0 ? void 0 : this.settings) === null || _a === void 0 ? void 0 : _a.Logger) === null || _b === void 0 ? void 0 : _b.Obfuscate) === null || _c === void 0 ? void 0 : _c.keys) || [],
            values: ((_f = (_e = (_d = this === null || this === void 0 ? void 0 : this.settings) === null || _d === void 0 ? void 0 : _d.Logger) === null || _e === void 0 ? void 0 : _e.Obfuscate) === null || _f === void 0 ? void 0 : _f.values) || [],
        });
    };
    Analytics.prototype.event = function (name, data) {
        if (data === void 0) { data = {}; }
        this.handle(name, data, 'event', this.callers.onEvent);
    };
    Analytics.prototype.interaction = function (name, data) {
        if (data === void 0) { data = {}; }
        this.handle(name, data, 'interaction', this.callers.onInteraction);
    };
    Analytics.prototype.onError = function (cb) {
        this.callers.error = cb;
    };
    Analytics.prototype.handle = function (name, data, type, fn) {
        try {
            var obfuscated = this.obfuscate(__assign(__assign({}, data), this.prepare()));
            fn({
                name: name,
                type: type,
                data: obfuscated,
            });
        }
        catch (e) {
            this.callers.error(e);
        }
    };
    return Analytics;
}());
exports.Analytics = Analytics;
