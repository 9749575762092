"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onMount = void 0;
var react_1 = require("react");
var onMount = function (func) {
    (0, react_1.useEffect)(function () {
        return func();
    }, []);
};
exports.onMount = onMount;
