"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useComponentStyle = exports.useDefaultComponentStyle = exports.useCodeleapContext = exports.StyleProvider = exports.StyleContextRef = exports.StyleContext = void 0;
var react_1 = __importStar(require("react"));
var constants_1 = require("../constants");
var utils_1 = require("../utils");
exports.StyleContext = (0, react_1.createContext)({
    found: false,
});
exports.StyleContextRef = react_1.default.createRef();
function useWindowSize() {
    var window = (global === null || global === void 0 ? void 0 : global.window) || { process: null };
    var isBrowser = !(window === null || window === void 0 ? void 0 : window.process);
    var _a = __read((0, react_1.useState)([0, 0]), 2), size = _a[0], setSize = _a[1];
    (0, utils_1.onMount)(function () {
        if (isBrowser) {
            // @ts-ignore
            setSize([window.innerWidth, window.innerWidth]);
        }
    });
    function handleResize() {
        if (isBrowser) {
            // @ts-ignore
            setSize([window.innerWidth, window.innerHeight]);
        }
    }
    (0, utils_1.onUpdate)(function () {
        if (isBrowser) {
            // @ts-ignore
            window.addEventListener('resize', handleResize);
            return function () {
                // @ts-ignore
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);
    return {
        width: size[0],
        height: size[1],
    };
}
var StyleProvider = function (_a) {
    var children = _a.children, variantProvider = _a.variantProvider, variants = _a.variants, logger = _a.logger, settings = _a.settings;
    var _b = __read((0, react_1.useState)(variantProvider.theme.theme), 2), theme = _b[0], setTheme = _b[1];
    (0, utils_1.onMount)(function () {
        variantProvider.onColorSchemeChange(function (t) {
            setTheme(t.theme);
        });
    });
    var value = {
        Theme: variantProvider.theme,
        currentTheme: theme,
        ComponentVariants: variants,
        provider: variantProvider,
        logger: logger || constants_1.silentLogger,
        Settings: settings,
        found: true,
    };
    // @ts-ignore
    exports.StyleContextRef.current = value;
    return (react_1.default.createElement(exports.StyleContext.Provider, { value: value }, children));
};
exports.StyleProvider = StyleProvider;
function useCodeleapContext() {
    var ctx = (0, react_1.useContext)(exports.StyleContext);
    return (ctx === null || ctx === void 0 ? void 0 : ctx.found) ? ctx : exports.StyleContextRef.current;
}
exports.useCodeleapContext = useCodeleapContext;
var getTransformedStyles = function (styles, transform) {
    if (!transform)
        return styles;
    if (Array.isArray(styles)) {
        return styles.reduce(function (acc, style) {
            return (0, utils_1.deepMerge)(acc, getTransformedStyles(style, transform));
        }, {});
    }
    return Object.fromEntries(Object.entries(styles || {}).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return [key, transform(value)];
    }));
};
function useDefaultComponentStyle(componentName, props) {
    var windowSize = useWindowSize();
    var _a = useCodeleapContext() || {}, CV = _a.ComponentVariants, provider = _a.provider, currentTheme = _a.currentTheme;
    try {
        var styles_1 = getTransformedStyles(props.styles, props.transform);
        var name_1 = componentName;
        if (componentName.startsWith('u:')) {
            name_1 = componentName.replace('u:', '');
        }
        var v_1 = CV === null || CV === void 0 ? void 0 : CV[name_1];
        if (!v_1) {
            throw new Error("Could not read context stylesheets for ".concat(name_1, ". Ensure it's being passed to <StyleProvider /> in the variants  prop."));
        }
        var stylesheet = (0, react_1.useMemo)(function () {
            return provider.getStyles(v_1, {
                // @ts-ignore
                variants: props.variants || [],
                // @ts-ignore
                responsiveVariants: props.responsiveVariants || {},
                rootElement: props.rootElement,
                // @ts-ignore
                styles: styles_1,
                size: windowSize,
            }, currentTheme);
        }, [
            JSON.stringify(props.variants),
            windowSize.height,
            windowSize.width,
            styles_1,
            props.rootElement,
            componentName,
            JSON.stringify((props === null || props === void 0 ? void 0 : props.variants) || []),
        ]);
        return stylesheet;
    }
    catch (e) {
        throw new Error('useDefaultComponentStyle with args ' + arguments + e);
    }
}
exports.useDefaultComponentStyle = useDefaultComponentStyle;
function useComponentStyle(styler) {
    var _a = useCodeleapContext(), currentTheme = _a.currentTheme, provider = _a.provider;
    try {
        var styles = (0, react_1.useMemo)(function () { return styler(provider.withColorScheme(currentTheme)); }, [currentTheme]);
        return styles;
    }
    catch (e) {
        throw new Error("useComponentStyle with args ".concat(arguments, " ").concat(e));
    }
}
exports.useComponentStyle = useComponentStyle;
