"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createForm = exports.getValidator = void 0;
var constants_1 = require("./constants");
var utils_1 = require("../../utils");
var constants_2 = require("./constants");
function getDefaultValue(field) {
    var _a, _b;
    switch (field.type) {
        case 'radio':
        case 'select':
            return ((_b = (_a = field.options) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value) || '';
        default:
            return constants_1.defaultFieldValues[field.type];
    }
}
function getValidator(validate) {
    if (!validate)
        return undefined;
    if (typeof validate === 'function') {
        return validate;
    }
    var yupModel = validate;
    return function (value) {
        var _a;
        try {
            yupModel.validateSync(value);
            return { valid: true, message: '' };
        }
        catch (e) {
            return { valid: false, message: ((_a = e === null || e === void 0 ? void 0 : e.errors) === null || _a === void 0 ? void 0 : _a.join(' ')) || '' };
        }
    };
}
exports.getValidator = getValidator;
function buildInitialFormState(name, form, inside) {
    var e_1, _a;
    if (inside === void 0) { inside = []; }
    var state = {};
    var props = {};
    var numberOfTextFields = 0;
    try {
        for (var _b = __values(Object.entries(form)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), k = _d[0], value = _d[1];
            var defaultValue = value.defaultValue, label = value.label, validate = value.validate, type = value.type, fieldConfig = __rest(value, ["defaultValue", "label", "validate", "type"]);
            var key = k;
            var fieldPathParts = __spreadArray(__spreadArray([], __read(inside), false), [key], false);
            var fieldPath = fieldPathParts.join('.');
            var fieldValue = null;
            if (type === 'text')
                numberOfTextFields += 1;
            if (false) {
                // const { props: subFieldProps, state } = buildInitialFormState(
                //   name,
                //   value.fields,
                //   fieldPathParts,
                // )
                // fieldValue = state
                // props = {
                //   ...props,
                //   ...subFieldProps,
                // }
            }
            else {
                fieldValue =
                    typeof defaultValue !== 'undefined'
                        ? defaultValue
                        : getDefaultValue(value);
            }
            var fieldProps = __assign({ id: "form-".concat(name, ":").concat(fieldPath), label: label || (0, utils_1.humanizeCamelCase)(k), changeEventName: constants_2.changeEventNames[type], type: type }, fieldConfig);
            delete fieldProps.fields;
            if (validate)
                fieldProps.validate = getValidator(validate);
            props[fieldPath] = fieldProps;
            state[key] = fieldValue;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return { state: state, props: props, numberOfTextFields: numberOfTextFields };
}
function createForm(name, formArgs) {
    var _a = buildInitialFormState(name, formArgs), state = _a.state, props = _a.props, numberOfTextFields = _a.numberOfTextFields;
    return {
        config: formArgs,
        defaultValue: state,
        staticFieldProps: props,
        name: name,
        numberOfTextFields: numberOfTextFields,
    };
}
exports.createForm = createForm;
