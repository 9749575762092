"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRedux = exports.createSlice = void 0;
var utils_1 = require("../../utils");
var toolkit_1 = require("@reduxjs/toolkit");
function createSlice(args) {
    var e_1, _a;
    var _this = this;
    var initialState = args.initialState, name = args.name, reducers = args.reducers, asyncReducers = args.asyncReducers, noMerge = args.noMerge;
    var reducerCases = [];
    try {
        for (var _b = __values(Object.keys(__assign(__assign({}, reducers), asyncReducers))), _c = _b.next(); !_c.done; _c = _b.next()) {
            var actionName = _c.value;
            var nameInReducer = "".concat(name, "/").concat(actionName);
            reducerCases.push(nameInReducer);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var buildActions = function (store) {
        var e_2, _a, e_3, _b;
        var actions = {};
        var _loop_1 = function (actionName, action) {
            var nameInReducer = "".concat(name, "/").concat(actionName);
            actions[actionName] = function (args) {
                store.dispatch({
                    type: nameInReducer,
                    payload: action(store.getState()[name], args),
                });
            };
        };
        try {
            for (var _c = __values(Object.entries(reducers)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), actionName = _e[0], action = _e[1];
                _loop_1(actionName, action);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
        var _loop_2 = function (actionName, action) {
            var nameInReducer = "".concat(name, "/").concat(actionName);
            var setState = function (payload) { return store.dispatch({ type: nameInReducer, payload: payload }); };
            actions[actionName] = function (args) { return __awaiter(_this, void 0, void 0, function () {
                var currentState, actionResult;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            currentState = store.getState()[name];
                            return [4 /*yield*/, action(currentState, setState, args)];
                        case 1:
                            actionResult = _a.sent();
                            return [2 /*return*/, actionResult !== undefined ? actionResult : store.getState()[name]];
                    }
                });
            }); };
        };
        try {
            for (var _f = __values(Object.entries(asyncReducers)), _g = _f.next(); !_g.done; _g = _f.next()) {
                var _h = __read(_g.value, 2), actionName = _h[0], action = _h[1];
                _loop_2(actionName, action);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return actions;
    };
    return {
        buildActions: buildActions,
        reducer: function (state, action) {
            if (state === void 0) { state = initialState; }
            if (reducerCases.includes(action.type)) {
                var actionName = action.type.split('/')[1];
                if (noMerge === null || noMerge === void 0 ? void 0 : noMerge.includes(actionName)) {
                    return action.payload;
                }
                return (0, utils_1.deepMerge)(state, action.payload);
            }
            return state;
        },
        initialState: initialState,
        name: name,
    };
}
exports.createSlice = createSlice;
function createRedux(slices, options) {
    var e_4, _a;
    var _b = options.middlewares, middlewares = _b === void 0 ? [] : _b, _c = options.composeArgs, composeArgs = _c === void 0 ? [] : _c, _d = options.createStore, createStore = _d === void 0 ? toolkit_1.createStore : _d;
    var reducers = {};
    var rootInitialState = {};
    var actionBuilders = {};
    try {
        for (var _e = __values(Object.values(slices)), _f = _e.next(); !_f.done; _f = _e.next()) {
            var _g = _f.value, reducer = _g.reducer, initialState = _g.initialState, buildActions = _g.buildActions, name_1 = _g.name;
            reducers[name_1] = reducer;
            rootInitialState[name_1] = initialState;
            actionBuilders[name_1] = buildActions;
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
        }
        finally { if (e_4) throw e_4.error; }
    }
    // @ts-ignore
    var store = createStore((0, toolkit_1.combineReducers)(reducers), rootInitialState, toolkit_1.compose.apply(void 0, __spreadArray([toolkit_1.applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false))], __read(composeArgs), false)));
    var actions = Object.fromEntries(Object.entries(actionBuilders).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], builder = _b[1];
        return [
            key,
            builder(store),
        ];
    }));
    return {
        store: store,
        actions: actions,
    };
}
exports.createRedux = createRedux;
