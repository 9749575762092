"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createDrawerStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createDrawerStyle(function () { return ({ wrapper: styles }); }); });
exports.DrawerStyles = __assign(__assign({}, presets), { default: createDrawerStyle(function (theme) { return ({
        wrapper: __assign(__assign(__assign({ zIndex: 3000, elevation: 3000, position: theme.IsBrowser ? 'fixed' : 'absolute' }, theme.presets.whole), { visibility: 'hidden', display: 'flex' }), theme.presets.row),
        body: __assign(__assign({}, theme.spacing.padding(1)), { flexDirection: 'column', flex: 1, overflowY: 'auto' }),
        overlay: {
            backgroundColor: theme.colors.black,
            height: theme.IsBrowser ? '100vh' : '100%',
            elevation: 1,
            zIndex: 1,
        },
        box: {
            backgroundColor: theme.colors.white,
            elevation: 2,
            zIndex: 2,
            flexDirection: 'column',
        },
        header: __assign(__assign({ backgroundColor: 'transparent', color: theme.colors.primary }, theme.presets.justifySpaceBetween), theme.spacing.padding(1)),
        footer: __assign({ backgroundColor: 'transparent' }, theme.spacing.padding(1)),
    }); }) });
