"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioInputStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createRadioInputStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createRadioInputStyle(function () { return ({ wrapper: styles }); }); });
exports.RadioInputStyles = __assign(__assign({}, presets), { default: createRadioInputStyle(function (theme) { return ({
        wrapper: __assign({ display: 'flex' }, theme.presets.column),
        itemWrapper: __assign(__assign(__assign({ display: 'flex' }, theme.presets.row), theme.presets.alignCenter), theme.spacing.paddingVertical(0.2)),
        listWrapper: __assign(__assign({}, theme.presets.column), theme.spacing.paddingVertical(1)),
    }); }) });
