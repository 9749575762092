"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderStyles = void 0;
var Text_1 = require("./Text");
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createSliderStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createSliderStyle(function () { return ({ wrapper: styles }); }); });
exports.SliderStyles = __assign(__assign({}, presets), { default: createSliderStyle(function (theme) { return ({
        wrapper: {},
        handle: {
            backgroundColor: theme.colors.primary,
        },
        selectedTrack: {
            backgroundColor: theme.colors.primary,
        },
        track: {
            backgroundColor: theme.colors.gray,
        },
        tooltip: {
            padding: theme.spacing.value(0.5),
            backgroundColor: theme.colors.primary,
            position: 'relative',
            borderRadius: theme.borderRadius.small,
        },
        'tooltip:visible': {
            opacity: 1,
        },
        'tooltip:hidden': {
            opacity: 0,
        },
        mark: {
            position: 'absolute',
            backgroundColor: theme.colors.primary,
            borderRadius: 29,
            height: 10,
            width: 10,
            top: -5,
        },
        trackLabels: __assign({ position: 'absolute', top: 8 }, (0, Text_1.assignTextStyle)('p3')(theme).text),
        tooltipArrow: {
            height: 6,
            width: 6,
            position: 'absolute',
            backgroundColor: theme.colors.primary,
            transform: [{ rotate: '45deg' }],
            bottom: -3,
            left: '50%',
        },
        tooltipText: __assign({ color: theme.colors.white }, (0, Text_1.assignTextStyle)('p3')(theme).text),
    }); }) });
