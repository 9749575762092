import React from 'react'
import { FormTypes, TypeGuards } from '@codeleap/common'
import { useStylesFor } from '@codeleap/web'
import { StyleSheets, StyleRegistry } from '@/app'
import { StyledProp, StyledComponent } from '@codeleap/styles'
import { ImageComposition } from '../app/stylesheets/Image'

export type ImageProps = {
  source: string | FormTypes.AnyFile
  alt: string
  objectFit?: React.CSSProperties['objectFit']
  style?: StyledProp<ImageComposition>
  type?: 'static' | 'dynamic'
}

export const Image: StyledComponent<typeof StyleSheets.ImageStyles, ImageProps> = (props) => {
  const {
    source,
    objectFit,
    style,
    ...rest
  } = props

  const styles = useStylesFor(Image.styleRegistryName, style)

  const imageFit = TypeGuards.isString(objectFit) && { objectFit }

  return (
    <img
      {...rest}
      src={source as HTMLImageElement['src']}
      // @ts-expect-error
      css={[styles.wrapper, imageFit]}
    />
  )
}

Image.styleRegistryName = 'Image'
Image.elements = ['wrapper']

StyleRegistry.registerComponent(Image)
