"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceError = void 0;
var defineError = function (errorName, args) {
    switch (errorName) {
        case 'maxRenders':
            return "".concat(args.name, " is rendering more than ").concat(args.maxRenders, "time per ").concat(args.throttleInterval / 1000, "second!\n      If you aware of this, you can disable it in the Settings.ts > Performancer.\n      ");
    }
};
var PerformanceError = /** @class */ (function (_super) {
    __extends(PerformanceError, _super);
    function PerformanceError(errorName, args) {
        var _this = _super.call(this, defineError(errorName, args)) || this;
        _this.name = 'Codeleap:Perf';
        return _this;
    }
    return PerformanceError;
}(Error));
exports.PerformanceError = PerformanceError;
