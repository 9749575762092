"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconStyles = void 0;
var presets_1 = require("../../presets");
var createDefaults_1 = require("../createDefaults");
var createIconStyle = (0, createDefaults_1.createDefaultVariantFactory)();
var presets = (0, presets_1.includePresets)(function (styles) { return createIconStyle(function () { return ({ icon: styles }); }); });
exports.IconStyles = __assign(__assign({}, presets), { default: createIconStyle(function (theme) { return ({
        icon: {
            color: theme.colors.icon,
        },
    }); }), white: createIconStyle(function (theme) { return ({
        icon: {
            color: theme.colors.white,
        },
    }); }), primary: createIconStyle(function (theme) { return ({
        icon: {
            color: theme.colors.primary,
        },
    }); }), negative: createIconStyle(function (theme) { return ({
        icon: {
            color: theme.colors.negative,
        },
    }); }), positive: createIconStyle(function (theme) { return ({
        icon: {
            color: theme.colors.positive,
        },
    }); }), small: createIconStyle(function (theme) { return ({
        icon: __assign({}, theme.sized(1)),
    }); }), medium: createIconStyle(function (theme) { return ({
        icon: __assign({}, theme.sized(2)),
    }); }), large: createIconStyle(function (theme) { return ({
        icon: __assign({}, theme.sized(3)),
    }); }) });
