"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateTheme = exports.createTheme = void 0;
var helpers_1 = require("./helpers");
var MediaQuery_1 = require("./MediaQuery");
var presets_1 = require("./presets");
var Spacing_1 = require("./Spacing");
var defaultAccessors = {
    screenSize: function () { return [0, 0]; },
};
var window = (global === null || global === void 0 ? void 0 : global.window) || { process: null };
/**
 * [[include:Theme.md]]
 */
function createTheme(values, accessors) {
    var _a;
    var getters = __assign(__assign({}, defaultAccessors), accessors);
    var isBrowser = !(window === null || window === void 0 ? void 0 : window.process);
    var initialTheme = values.initialTheme || ((_a = Object.keys(values.colors)) === null || _a === void 0 ? void 0 : _a[0]);
    if (!initialTheme || !values.colors[initialTheme]) {
        throw new Error("\n      Could not load initial theme colors. make sure all keys of Theme.colors have a corresponding color set like:\n      ...\n      colors: {\n        light: {\n          // your colors here\n        }\n      }\n      ...\n      And that your initialTheme is correctly writen (if specified)  \n    ");
    }
    return __assign(__assign({}, values), { theme: initialTheme, hooks: (0, MediaQuery_1.breakpointHooksFactory)(values.breakpoints, getters.screenSize), media: (0, MediaQuery_1.buildMediaQueries)(values.breakpoints), spacing: __assign(__assign(__assign({ base: values.spacing }, (0, Spacing_1.spacingFactory)(values.spacing, 'padding')), (0, Spacing_1.spacingFactory)(values.spacing, 'margin')), (0, Spacing_1.spacingFactory)(values.spacing, 'gap')), border: (0, helpers_1.createBorderHelpers)(values, isBrowser, initialTheme), presets: __assign(__assign({}, presets_1.defaultPresets), values.presets), effects: __assign({}, values.effects), semiCircle: function (side) { return ({
            width: side,
            height: side,
            borderRadius: values.borderRadius.small,
        }); }, circle: function (side) { return ({
            width: side,
            height: side,
            borderRadius: values.borderRadius.rounded,
        }); }, square: function (side) { return ({
            width: side,
            height: side,
        }); }, sized: function (size) { return ({
            width: size * values.spacing,
            height: size * values.spacing,
        }); }, IsBrowser: isBrowser });
}
exports.createTheme = createTheme;
function validateTheme(t) {
    return t;
}
exports.validateTheme = validateTheme;
