"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentryService = void 0;
var types_1 = require("./types");
var SentryService = /** @class */ (function () {
    function SentryService(settings) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.use = (_a = settings === null || settings === void 0 ? void 0 : settings.Sentry) === null || _a === void 0 ? void 0 : _a.enable;
        this.sentry = (_b = settings === null || settings === void 0 ? void 0 : settings.Sentry) === null || _b === void 0 ? void 0 : _b.provider;
        if (this.use) {
            var isDebug = ((_c = settings === null || settings === void 0 ? void 0 : settings.Sentry) === null || _c === void 0 ? void 0 : _c.debug) || false;
            if (isDebug)
                console.log('> > > Initializing Sentry', settings.Sentry);
            var initObj = __assign({ dsn: settings.Sentry.dsn, debug: isDebug, 
                // @ts-expect-error - These are provided by platform specific Sentry providers and plugins
                integrations: [] }, (_d = settings === null || settings === void 0 ? void 0 : settings.Sentry) === null || _d === void 0 ? void 0 : _d.initArgs);
            if ((_e = settings === null || settings === void 0 ? void 0 : settings.Sentry) === null || _e === void 0 ? void 0 : _e.beforeBreadcrumb) {
                initObj.beforeBreadcrumb = (_f = settings === null || settings === void 0 ? void 0 : settings.Sentry) === null || _f === void 0 ? void 0 : _f.beforeBreadcrumb;
            }
            (_h = (_g = this.sentry) === null || _g === void 0 ? void 0 : _g.init) === null || _h === void 0 ? void 0 : _h.call(_g, initObj);
        }
    }
    SentryService.prototype.captureBreadcrumb = function (type, content) {
        if (!this.use)
            return;
        var _a = __read(content, 3), message = _a[0], data = _a[1], category = _a[2];
        var sentryArgs = {
            message: message,
            data: data,
            category: category,
            level: types_1.SentrySeverityMap[type],
            type: '',
        };
        this.sentry.addBreadcrumb(sentryArgs);
    };
    SentryService.prototype.sendLog = function (err) {
        if (!this.use)
            return;
        this.sentry.captureException(err);
    };
    return SentryService;
}());
exports.SentryService = SentryService;
