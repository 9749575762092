"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInterval = void 0;
var react_1 = require("react");
function useInterval(callback, interval) {
    var intervalRef = (0, react_1.useRef)(null);
    function clear() {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    }
    function start() {
        intervalRef.current = setInterval(callback, interval);
    }
    return {
        clear: clear,
        start: start,
        interval: intervalRef.current,
    };
}
exports.useInterval = useInterval;
