"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.spacingFactory = void 0;
var types_1 = require("./types");
function spacingFactory(base, property) {
    var _a;
    var functions = types_1.spacingVariants.map(function (v) { return [
        "".concat(property).concat(v),
        function (n) {
            var _a, _b, _c, _d;
            var value = typeof n === 'string' ? n : base * n;
            if (property === 'gap') {
                return {
                    gap: value,
                };
            }
            switch (v) {
                case 'Horizontal':
                    return _a = {},
                        _a["".concat(property, "Left")] = value,
                        _a["".concat(property, "Right")] = value,
                        _a;
                case 'Vertical':
                    return _b = {},
                        _b["".concat(property, "Top")] = value,
                        _b["".concat(property, "Bottom")] = value,
                        _b;
                case '':
                    return _c = {},
                        _c["".concat(property, "Top")] = value,
                        _c["".concat(property, "Left")] = value,
                        _c["".concat(property, "Right")] = value,
                        _c["".concat(property, "Bottom")] = value,
                        _c;
                default:
                    return _d = {},
                        _d["".concat(property).concat(v)] = value,
                        _d;
            }
        },
    ]; });
    return __assign((_a = {}, _a["".concat(property)] = function (n) {
        var _a;
        return (_a = {},
            _a["".concat(property)] = typeof n === 'string' ? n : base * n,
            _a);
    }, _a.value = function (n) {
        if (n === void 0) { n = 1; }
        return base * n;
    }, _a), Object.fromEntries(functions));
}
exports.spacingFactory = spacingFactory;
