"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeEventNames = exports.defaultFieldValues = void 0;
exports.defaultFieldValues = {
    checkbox: false,
    text: '',
    file: null,
    multipleFile: [],
    'range-slider': [0, 100],
    slider: 1,
    number: '',
    list: [],
    switch: false,
    date: null,
};
exports.changeEventNames = {
    checkbox: 'onValueChange',
    'range-slider': 'onValueChange',
    slider: 'onValueChange',
    select: 'onValueChange',
    radio: 'onValueChange',
    text: 'onChangeText',
    file: 'onFileSelect',
    multipleFile: 'onFileSelect',
    number: 'onChangeText',
    list: 'onValueChange',
    switch: 'onValueChange',
    date: 'onValueChange',
};
