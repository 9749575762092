"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.silentLogger = void 0;
var Logger_1 = require("./tools/Logger");
exports.silentLogger = new Logger_1.Logger({
    Logger: {
        Level: 'silent',
        IgnoreWarnings: [
            "Require cycle:",
            "Require cycles are allowed",
        ],
    },
});
