"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNestedStylesByKey = void 0;
var react_1 = require("react");
var misc_1 = require("../misc");
function useNestedStylesByKey(match, variantStyles) {
    return (0, react_1.useMemo)(function () {
        return (0, misc_1.getNestedStylesByKey)(match, variantStyles);
    }, []);
}
exports.useNestedStylesByKey = useNestedStylesByKey;
