"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useForceRender = void 0;
var react_1 = require("react");
function useForceRender() {
    var _a = __read((0, react_1.useReducer)(function (x) { return x + 1; }, 0), 2), _ = _a[0], forceRender = _a[1];
    return forceRender;
}
exports.useForceRender = useForceRender;
